import React, { Component } from 'react';
import Moment from 'moment';
import InputDate from '../../components/Form/input-date';
import Select from '../../components/Form/select';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



class InputDatetime extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      label: props.label,
      type: props.type ?? '',
      format: props.format,
      weekday: props.weekday ?? '0',
      datelabel: props.datelabel ?? lang.t('calendar:form.date'),
      date: props.date ?? Moment().format('DD/MM/YYYY'),
      hour: props.hour ?? '00',
      min: props.min ?? '00',
      start: props.start ?? '0',
      hrs: [],
      mins: [],
      days: [],
      error: props.error ?? false,
      errormsg: props.errormsg ?? '',
    };
  }


  componentDidMount() {
    var tmp1 = []
    var tmp2 = []
    var tmp3 = []
    for(var i=0; i<24; i++) {
      if(i<10) {
        tmp1.push({name: '0'+i, value: '0'+i})
      } else {
        tmp1.push({name: i, value: ''+i})
      }
    }
    for(var j=0; j<12; j++) {
      if(j<2) {
        tmp2.push({name: '0'+j*5, value: '0'+j*5})
      } else {
        tmp2.push({name: j*5, value: ''+j*5})
      }
    }
    for(var k=0; k<13; k++) {
      var label = lang.t('calendar:form.in')+' '+k+' '+lang.t('calendar:form.weeks')
      if(k === 0) {
        label = lang.t('calendar:form.thisweek')
      }
      if(k === 1) {
        label = lang.t('calendar:form.nextweek')
      }
      tmp3.push({name: label, value: ''+k*7})
    }
    this.setState({
      hrs: tmp1,
      mins: tmp2,
      days: tmp3,
    })
  }


  static getDerivedStateFromProps(props) {
    return {
      label: props.label,
      type: props.type ?? '',
      weekday: props.weekday ?? '0',
      datelabel: props.datelabel ?? lang.t('calendar:form.date'),
      date: props.date ?? Moment().format('DD/MM/YYYY'),
      hour: props.hour ?? '00',
      min: props.min ?? '00',
      start: props.start ?? '0',
      error: props.error ?? false,
      errormsg: props.errormsg ?? '',
    }
  }


  renderError() {
    if(this.state.error) {
      return (
        <p className="error clear">{this.state.errormsg}</p>
      )
    }
  }


  renderStart() {
    if(this.state.type === 'recurring') {
      return (
        <div className="clear col-12">
          <Select label={lang.t('calendar:form.starts')} value={this.state.start} values={this.state.days} onChange={(event) => this.props.setStart(event)}/>
        </div>
      )
    }
  }


  renderDay() {
    if(this.state.type === 'recurring' || this.state.type === 'recurringedit') {
      return (
        <Select label={lang.t('calendar:form.weekday')} value={this.state.weekday} values={[{name: lang.t('calendar:label.monday'),  value: '0'}, {name: lang.t('calendar:label.tuesday'),  value: '1'}, {name: lang.t('calendar:label.wednesday'),  value: '2'}, {name: lang.t('calendar:label.thursday'),  value: '3'}, {name: lang.t('calendar:label.friday'),  value: '4'}, {name: lang.t('calendar:label.saturday'),  value: '5'}, {name: lang.t('calendar:label.sunday'),  value: '6'}]} onChange={(event) => this.props.setDay(event)}/>
      ) 
    } else {
      return (
        <InputDate label={this.state.datelabel} value={Moment(this.state.date, 'DD/MM/YYYY').format('YYYY-MM-DD')} format='YYYY-MM-DD' min={Moment().add(-4, 'weeks').format('YYYY-MM-DD')} max={Moment().add(3, 'months').format('YYYY-MM-DD')} onChange={(event) => this.props.setDay(Moment(event).format('DD/MM/YYYY'))}/>
      )
    }
  }


  render() {
    return (
      <div className="form-item">
        <div className="col-6">
          {this.renderDay()}
        </div>
        <div className="col-3">
          <Select label={lang.t('calendar:form.time')} value={this.state.hour} values={this.state.hrs} onChange={(event) => this.props.setHour(event)}/>
        </div>
        <div className="col-3">
          <Select label='&nbsp;' value={this.state.min} values={this.state.mins} onChange={(event) => this.props.setMin(event)}/>
        </div>
        {this.renderStart()}
        {this.renderError()}
      </div>
    )
  }
}


export default withTranslation(['calendar'])(InputDatetime);