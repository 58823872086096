import React, { Component } from 'react';
import Moment from 'moment';
import Firebase from 'firebase';
import Connector from '../../data/Connector';
import HelperCal from '../../helper/Calendar';
import HelperPage from '../../helper/Page';
import HelperBusiness from '../../helper/Business';
import * as ROUTES from '../../constants/routes';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import ListEmpty from '../../components/List/empty';
import InputToggle from '../../components/Form/toggle';
import ModalLeaderboard from '../../components/Modals/leaderboard';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class ResultsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      item: null,
      session: '',
      date: '',
      program: null,
      exercises: global.exercises,
      clients: global.clients,
      user: '',
      best: null,
      attendees: [],
      current: 0,
      // Edit elements
      blocks: [],
      mins: [],
      secs: [],
      changed: false,
      isTraining: false,
      bestGroup: null,
      showModalLeader: false,
      leaderBlock: null,
    };
  }


  componentDidMount() {
    Connector.setUser()
    document.title = 'Session Results - PT Mate'
    window.Intercom("update")
    window.scrollTo(0, 0)
    HelperPage.checkMessage()
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.getClients())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('archiveLoaded', (event) => this.configureData())
    EventEmitter.subscribe('trainingLoaded', (event) => this.configureData())
    EventEmitter.subscribe('archiveTrainingLoaded', (event) => this.configureData())
    EventEmitter.subscribe('archiveLoaded', (event) => this.configureData())
    EventEmitter.subscribe('programsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('exercisesLoaded', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  getClients() {
    if(this._isMounted) {
      this.setState({
        clients: global.clients
      }, () => {
        this.configureData()
      });
    }
  }


  configureData() {
    if(this._isMounted) {
      var tmp1 = []
      var tmp2 = []
      var tblk = []
      for(var j=0; j<60; j++) {
        tmp1.push(j+' min')
        tmp2.push(j+' sec')
      }

      var user = ''
      var program = null
      var id = ''
      var session = ''
      var date = ''
      var list = global.sessions
      var isTraining = false
      var attendees = []
      var sitem = null

      var arr = this.props.location.pathname.split('/')
      session = arr[arr.length-1]

      for(var s1 of global.archive) {
        if(s1.id === session) {
          list = global.archive
        }
      }

      for(var s2 of global.sessionsTraining) {
        if(s2.id === session) {
          list = global.sessionsTraining
          isTraining = true
        }
      }

      for(var s3 of global.archiveTraining) {
        if(s3.id === session) {
          list = global.archiveTraining
          isTraining = true
        }
      }

      for(var item of list) {
        if(item.id === session) {
          sitem = item
          program = item.program[0]
          id = item.program[0].id
          date = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('lll')
          if(item.data.group) {
            attendees = item.data.clients
          }

          for(var client of global.clients) {
            if(client.id === item.data.client) {
              user = client.id
              if(client.data.uid !== '') {
                user = client.data.uid
              }
              Connector.loadClientBest(user)
              setTimeout(() => {
                this.setState({
                  best: global.clientBest
                })
              }, 3500);
            }
          }

          if(item.data.group) {
            global.clientBestGroup = []
            for(var client2 of item.data.clients) {
              for(var cl of global.clients) {
                if(cl.id === client2 && cl.data.uid !== '') {
                  Connector.loadClientBestGroup(cl.data.uid)
                }
                if(cl.id === client2 && cl.data.uid === '') {
                  Connector.loadClientBestGroup(cl.id)
                }
              }
            }
            setTimeout(() => {
              this.setState({
                bestGroup: global.clientBestGroup
              })
            }, 3500);
          }

          for(var block of item.program[0].blocks) {
            var min = parseInt(block.data.rounds/60)
            var rmin = parseInt(block.data.rounds/60)
            var rsec = parseInt(block.data.rounds)-(rmin*60)
            if(block.data.timeRes !== undefined && block.data.timeRes !== null) {
              rmin = parseInt(block.data.timeRes/60)
              rsec = block.data.timeRes-(rmin*60)
            }
            var rming = []
            var rsecg = []
            var arrt = []
            var cycles = 1
            var simple = false

            var ssimple = false
            var usimple = "reps"

            if(block.data.cycles !== undefined) {
              cycles = block.data.cycles
            }
            if(item.data.group) {
              if(block.data.timeResGroup !== undefined && block.data.timeResGroup !== null) {
                arrt = block.data.timeResGroup
              }
              if(arrt.length === 0) {
                var att1 = 0
                while(att1 < attendees.length) {
                  rming.push(0)
                  rsecg.push(0)
                  att1++
                }
              } else {
                for(var tme of arrt) {
                  rming.push(parseInt(tme/60))
                  rsecg.push(tme-(parseInt(tme/60)*60))
                }
              }
            }

            var ex = []
            var tmpb = 0
            var tmp0 = 0
            var tmp1 = 0
            var tmp2 = 0

            var exWeightType = []
            var ei = 0
            while(ei < block.data.exName.length) {
              exWeightType.push('per')
              ei++
            }
            if(block.data.exWeightType !== undefined) {
              exWeightType = block.data.exWeightType
            }

            // Simple results
            if(block.data.simple) {
              if(block.data.unitSimple !== undefined && block.data.unitSimple !== '') {
                usimple = block.data.unitSimple
              }
              if(block.data.scaledSimple !== undefined) {
                if(block.data.scaledSimple.length > this.state.current) {
                  ssimple = block.data.scaledSimple[this.state.current]
                }
              }

              if(block.data.type === 0) {
                if(block.data.amrapSimple !== undefined) {
                  if(block.data.amrapSimple.length > this.state.current) {
                    var tmpa = block.data.amrapSimple[this.state.current].split('+')
                    tmp1 = parseInt(tmpa[0])
                    tmp2 = 0
                    if(tmpa.length > 1) {
                      tmp2 = parseInt(tmpa[1])
                    }
                  }
                }
              } else if(block.data.type !== 0 && block.data.type !== 5) {
                if(block.data.valueSimple !== undefined) {
                  if(block.data.valueSimple.length > this.state.current) {
                    tmp1 = block.data.valueSimple[this.state.current]
                  }
                }
              }
            }
            simple = block.data.simple

            for(var i=0; i<block.data.exName.length; i++) {
              var min1 = parseInt(block.data.exWork[i]/60)
              var min2 = parseInt(block.data.exRest[i]/60)
              var min3 = parseInt(block.data.exResWeight[i]/60)
              var m = []
              var s = []

              var unit = ''
              if(block.data.exUnits !== undefined) {
                if(block.data.exUnits.length > i) {
                  unit = block.data.exUnits[i]
                }
              }

              var notes = ''
              if(block.data.exNotes !== undefined) {
                if(block.data.exNotes.length > i) {
                  notes = block.data.exNotes[i]
                }
              }

              tmpb += parseInt(block.data.exReps[i])
              if(!item.data.group) {
                tmp0 += parseInt(block.data.exResReps[i])
              }

              // Reps
              var reps = block.data.exReps[i]
              var repsround = block.data.exRepsRounds[i]
              var grouprounds = block.data.exRepsRounds[i]
              if(attendees.length === 0) {
                // 1:1
                if(block.data.exResReps !== undefined) {
                  if(block.data.exResReps.length > i) {
                    tmp0 += parseInt(block.data.exResReps[i])
                    if(block.data.exResReps[i] !== 0 && block.data.exResReps[i] !== undefined) {
                      reps = block.data.exResReps[i]
                    }
                  }
                }
                if(block.data.exResRepsRounds !== undefined) {
                  if(block.data.exResRepsRounds.length > i) {
                    if(block.data.exResRepsRounds[i] !== '' && block.data.exResRepsRounds[i] !== undefined) {
                      repsround = block.data.exResRepsRounds[i]
                    }
                  }
                }
              } else {
                // Group
                if(block.data.exResRepsGroup !== undefined) {
                  if(block.data.exResRepsGroup.length > i) {
                    var areps = block.data.exResRepsGroup[i].split('-')
                    if(areps.length > this.state.current) {
                      if(areps[this.state.current] !== '') {
                        reps = areps[this.state.current]
                        tmp0 += parseInt(areps[this.state.current])
                      }
                    }
                  }
                  for(var im of block.data.exResRepsGroup) {
                    var mins = parseInt(im/60)
                    m.push(parseInt(im/60))
                    s.push(parseInt(im)-(mins*60))
                  }
                  if(m.length < attendees.length) {
                    for(var ia=m.length; ia<attendees.length; ia++) {
                      m.push(0)
                      s.push(0)
                    }
                  }
                }
                if(block.data.exResRepsRounds !== undefined) {
                  if(block.data.exResRepsRounds.length > i) {
                    var arepsr = block.data.exResRepsRounds[i].split('|')
                    if(arepsr.length > this.state.current) {
                      repsround = arepsr[this.state.current]
                      grouprounds = block.data.exResRepsRounds[i]
                    }
                  }
                }
              }

              // Weight
              var weight = block.data.exWeight[i]
              var weightround = block.data.exWeightRounds[i]
              var groupweight = block.data.exWeightRounds[i]
              if(attendees.length === 0) {
                // 1:1
                if(block.data.exResWeight !== undefined) {
                  if(block.data.exResWeight.length > i) {
                    if(block.data.exResWeight[i] !== 0 && block.data.exResWeight[i] !== undefined) {
                      weight = block.data.exResWeight[i]
                    }
                  }
                }
                if(block.data.exResWeightRounds !== undefined) {
                  if(block.data.exResWeightRounds.length > i) {
                    if(block.data.exResWeightRounds[i] !== '' && block.data.exResWeightRounds[i] !== undefined) {
                      weightround = block.data.exResWeightRounds[i]
                    }
                  }
                }
              } else {
                // Group
                if(block.data.exResWeightGroup !== undefined) {
                  if(block.data.exResWeightGroup.length > i) {
                    var aweight = block.data.exResWeightGroup[i].split('-')
                    if(aweight.length > this.state.current) {
                      if(aweight[this.state.current] !== '') {
                        weight = aweight[this.state.current]
                      }
                    }
                  }
                }
                if(block.data.exResWeightRounds !== undefined) {
                  if(block.data.exResWeightRounds.length > i) {
                    var aweightr = block.data.exResWeightRounds[i].split('|')
                    groupweight = block.data.exResWeightRounds[i]
                    if(aweightr.length > this.state.current) {
                      weightround = aweightr[this.state.current]
                    }
                  }
                }
              }

              ex.push({
                id: block.data.exId[i],
                name: block.data.exName[i],
                tool: block.data.exTool[i],
                image: block.data.exImage[i],
                cat: block.data.exCat[i],
                type: block.data.exType[i],
                reps: block.data.exReps[i],
                repsRound: grouprounds,
                repsRoundStatic: block.data.exRepsRounds[i],
                resRepsGroup: block.data.exResRepsGroup[i],
                // Updating reps data
                resReps: reps,
                resRepsRound: repsround,
                
                weight: block.data.exWeight[i],
                weightRoundStatic: block.data.exWeightRounds[i],
                weightRound: groupweight,
                resWeightGroup: block.data.exResWeightGroup[i],
                // Updating weight data
                resWeight: weight,
                resWeightRound: weightround,

                weightType: exWeightType[i],
                work: block.data.exWork[i],
                rest: block.data.exRest[i],
                wmin: min1,
                wsec: block.data.exWork[i]-(min1*60),
                rmin: min2,
                rsec: block.data.exRest[i]-(min2*60),
                resMin: min3,
                resSec: block.data.exResWeight[i]-(min3*60),
                resMinGroup: m[i],
                resSecGroup: s[i],
                unit: unit,
                notes: notes,
              })
            }
            var name = ''
            if(block.data.name !== undefined) {
              name = block.data.name
            }

            if(!block.data.simple) {
              tmp1 = 0
              tmp2 = 0
              if(tmp0 > 0) {
                tmp1 = Math.floor(tmp0/tmpb)
                tmp2 = tmp0 % tmpb
              }
            }

            // Simple
            var snote = ''
            var snotes = ''
            if(block.data.notesResSimple !== undefined) {
              snote = block.data.notesResSimple
              snotes = block.data.notesResSimple
              if(attendees.length > 0) {
                var ars = block.data.notesResSimple.split('|')
                if(ars.length > this.state.current) {
                  snote = ars[this.state.current]
                }
              }
            }

            tblk.push({
              id: block.id,
              type: block.data.type,
              cat: block.data.cat,
              rounds: block.data.rounds,
              cycles: cycles,
              name: name,
              emom: block.data.emom,
              notes: block.data.notes,
              resNotes: block.data.notesRes,
              exercises: ex,
              min: min,
              sec: block.data.rounds-(min*60),
              resMin: rmin,
              resSec: rsec,
              resMinGroup: rming,
              resSecGroup: rsecg,
              show: false,
              simple: simple,
              tmp1: tmp1,
              tmp2: tmp2,
              results: block.data.logResults,
              simpleNote: snote,
              simpleNotes: snotes,
              unitSimple: usimple,
              scaledSimple: ssimple,
              scaledSimple2: block.data.scaledSimple,
              valueSimple2: block.data.valueSimple
            })
          }
        }
      }
    }

    this.setState({
      id: id,
      session: session,
      item: sitem,
      date: date,
      mins: tmp1,
      secs: tmp2,
      exercises: global.exercises,
      program: program,
      blocks: tblk,
      user: user,
      isTraining: isTraining,
      attendees: attendees
    })
  }


  getClientName(id) {
    var label = 'Client'
    for(var item of global.clients) {
      if(item.id === id) {
        label = item.data.name
      }
    }
    return label
  }



  // Interactive stuff ------------------------------------------------------------



  setTimeMin(event, index) {
    var tmp = this.state.blocks
    if(this.state.attendees.length === 0) {
      tmp[index].resMin = event.target.value
    } else {
      tmp[index].resMinGroup[this.state.current] = event.target.value
    }
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  setTimeSec(event, index) {
    var tmp = this.state.blocks
    if(this.state.attendees.length === 0) {
      tmp[index].resSec = event.target.value
    } else {
      tmp[index].resSecGroup[this.state.current] = event.target.value
    }
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  onChangeReps(event, num, index) {
    var tmp = this.state.blocks
    var val =  parseInt(event.target.value)
    if(event.target.value === '') {
      val = 0
    }
    tmp[num].exercises[index].resReps = val

    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  onChangeRepsRound(event, num, index) {
    var tmp = this.state.blocks
    var val = event.target.value
    if(val === '') { val = '0' }
    val = val.replace(/[^0-9-]/g, "")
    val = val.replace('--', '-')
    var reps = 0
    var ar = val.split('-')
    for(var a of ar) {
      if(a !== '') {
        reps += parseInt(a)
      }
      
    }
    tmp[num].exercises[index].resReps = reps
    tmp[num].exercises[index].resRepsRound = val
    
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  onChangeWeight(event, num, index) {
    var tmp = this.state.blocks
    var val = parseFloat(event.target.value)
    if(event.target.value === '') {
      val = 0
    }
    tmp[num].exercises[index].resWeight = val

    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  onChangeWeightRound(event, num, index) {
    var tmp = this.state.blocks
    var val = event.target.value
    var vals = ''
    if(val === '') { val = '0' }
    val = val.replace(/[^0-9-.]/g, "")
    val = val.replace('--', '-')
    val = val.replace('.-', '-')
    val = val.replace('. ', '')
    val = val.replace('..', '.')
    var weight = 0
    var ar = val.split('-')
    for(var a of ar) {
      if(a !== '') {
        if(parseFloat(a) > weight) {
          weight = parseFloat(a)
        }
      }
      
    }
    tmp[num].exercises[index].resWeight = weight
    tmp[num].exercises[index].resWeightRound = val
    
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  setTimeMinEx(event, num, index) {
    var tmp = this.state.blocks
    if(this.state.attendees.length === 0) {
      tmp[num].exercises[index].resMin = event.target.value
    } else {
      tmp[num].exercises[index].resMinGroup[this.state.current] = event.target.value
    }
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  setTimeSecEx(event, num, index) {
    var tmp = this.state.blocks 
    if(this.state.attendees.length === 0) {
      tmp[num].exercises[index].resSec = event.target.value
    } else {
      tmp[num].exercises[index].resSecGroup[this.state.current] = event.target.value
    }
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  toggleNotes(index) {
    var tmp = this.state.blocks
    tmp[index].show = !tmp[index].show
    this.setState({
      blocks: tmp,
    })
  }


  setNotes(event, index) {
    var tmp = this.state.blocks
    tmp[index].resNotes = event.target.value
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  setSimple(event, index) {
    var tmp = this.state.blocks
    tmp[index].simpleNote = event.target.value
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  setAR1(event, index) {
    var tmp = this.state.blocks
    if(!tmp[index].simple) {
      var reps = 0
      if(tmp[index].tmp2 !== undefined && tmp[index].tmp1 !== '') {
        reps = parseInt(tmp[index].tmp2)
      }
      for(var ex of tmp[index].exercises) {
        ex.resReps = ex.reps*parseInt(event.target.value)
        if(reps > 0) {
          if(reps > ex.reps) {
            ex.resReps += ex.reps
            reps -= ex.reps
          } else {
            ex.resReps += reps
            reps = 0
          }
        }
      }
    }
    tmp[index].tmp1 = event.target.value
    this.setState({
      blocks: tmp,
      changed: true
    })
  }


  setAR2(event, index) {
    var tmp = this.state.blocks
    var rounds = 0
    var reps = 0
    if(event.target.value !== '') {
      reps = parseInt(event.target.value)
    }
    if(tmp[index].tmp1 !== undefined && tmp[index].tmp1 !== '') {
      rounds = parseInt(tmp[index].tmp1)
    }
    for(var ex of tmp[index].exercises) {
      ex.resReps = ex.reps*rounds
      if(reps > 0) {
        if(reps > ex.reps) {
          ex.resReps += ex.reps
          reps -= ex.reps
        } else {
          ex.resReps += reps
          reps = 0
        }
      }
    }
    tmp[index].tmp2 = event.target.value
    this.setState({
      blocks: tmp,
      changed: true
    })
  }


  updateScaled(index, value) {
    var tmp = this.state.blocks
    tmp[index].scaledSimple = value
    this.setState({
      blocks: tmp,
      changed: true
    })
  }



  // Update results ------------------------------------------------------------



  changeClient(event) {
    this.updateResults()
    setTimeout(() => {
      this.setState({
        current: parseInt(event)
      }, () => {
        this.configureData()
      })
    }, 200);
  }


  updateResults() {
    var tmp  = this.state.program.blocks
    for(var i=0; i<this.state.blocks.length; i++) {
      var block = this.state.blocks[i]
      var weight = []
      var reps = []
      var repsr = []
      var repsgroup = [] //Group reps
      var weightgroup = [] //Group weight
      var tgarr = []
      var weightr = []
      var excount = 0

      // Harmonize AMRAP
      if(block.type === 0 && !block.simple) {
        if(block.tmp2 !== 0 && block.tmp2 !== '') {
          var tr = 0
          for(var ex1 of block.exercises) {
            tr += ex1.reps
          }
          if(block.tmp2 > tr) {
            block.tmp1 = parseInt(block.tmp1)+Math.floor(block.tmp2/tr)
            block.tmp2 = block.tmp2 % tr
          }
        }
      }
      
      for(var ex of block.exercises) {
        var rn = 0
        if(block.tmp1 !== '') {
          rn = parseInt(ex.reps*block.tmp1)
        }
        if(block.type !== 0) {
          reps.push(parseInt(ex.resReps))
        } else {
          var add = 0
          if(block.tmp2 !== 0 && block.tmp2 !== '') {
            add = block.tmp2
            for(var rni=0; rni<excount; rni++) {
              add -= this.state.program.blocks[i].data.exReps[rni]
            }
            if(add > ex.reps) {
              add = ex.reps
            }
            if(add < 0) {
              add = 0
            }
            
          }
          rn += parseInt(add)
          reps.push(parseInt(rn))
        }
        
        var rrl = ex.resRepsRound
        var exrepsgroup = ''
        if(rrl[0] === '-') {
          rrl = rrl.substring(1, rrl.length)
        }
        if(rrl[rrl.length-1] === '-') {
          rrl = rrl.substring(0, rrl.length-1)
        }

        var wrl = ex.resWeightRound
        var exweightgroup = ''
        if(wrl[0] === '-') {
          wrl = wrl.substring(1, wrl.length)
        }
        if(wrl[wrl.length-1] === '-') {
          wrl = wrl.substring(0, wrl.length-1)
        }

        // Group sessions
        if(this.state.attendees.length > 0) {
          // Reps
          rrl = ''
          exrepsgroup = ''
          wrl = ''
          exweightgroup = ''
          var argroup1 = ex.resRepsGroup.split('-')
          var argroup11 = ex.repsRound.split('|')
          var argroup2 = ex.resWeightGroup.split('-')
          var argroup21 = ex.weightRound.split('|')
          for(var ia=0; ia<this.state.attendees.length; ia++) {
            if(ia === this.state.current) {
              exrepsgroup += '-'+ex.resReps
              rrl += ex.resRepsRound+'|'
              exweightgroup += '-'+ex.resWeight
              wrl += ex.resWeightRound+'|'
            } else {
              // Reps
              if(argroup1.length > ia) {
                exrepsgroup += '-'+argroup1[ia]
              } else {
                //exrepsgroup += '-'+ex.reps
                exrepsgroup += '-0'
              }
              if(argroup11.length > ia) {
                rrl += argroup11[ia]+'|'
              } else {
                //rrl += ex.repsRound+'|'
                rrl += '0|'
              }
              // Weight
              if(argroup2.length > ia) {
                exweightgroup += '-'+argroup2[ia]
              } else {
                //exweightgroup += '-'+ex.weight
                exweightgroup += '-0'
              }
              if(argroup21.length > ia) {
                wrl += argroup21[ia]+'|'
              } else {
                //wrl += ex.weightRound+'|'
                wrl += '0|'
              }
            }
          }
          exrepsgroup = exrepsgroup.substring(1, exrepsgroup.length)
          exweightgroup = exweightgroup.substring(1, exweightgroup.length)
        }

        repsr.push(rrl)
        repsgroup.push(exrepsgroup)
        weightr.push(wrl)
        weightgroup.push(exweightgroup)

        if(ex.tool !== 6 && ex.tool !== 7) {
          weight.push(parseFloat(ex.resWeight))
        } else {
          weight.push(parseInt(ex.resMin)*60+parseInt(ex.resSec))
        }
        if(this.state.attendees.length === 0) {
          this.updateBest(ex)
        } else {
          this.updateBestGroup(ex)
        }
        excount++
      }

      // Completion time
      if(block.type === 0 || block.type === 5) {
        if(this.state.attendees.length > 0) {
          for(var bi1=0; bi1<this.state.attendees.length; bi1++) {
            var num = parseInt(block.resMinGroup[bi1])*60+parseInt(block.resSecGroup[bi1])
            if(isNaN(num)) {
              tgarr.push(0)
            } else {
              tgarr.push((parseInt(block.resMinGroup[bi1])*60+parseInt(block.resSecGroup[bi1])))
            }
          }
          tmp[i].data.timeResGroup = tgarr
        }
      }

      tmp[i].data.notesRes = block.resNotes
      tmp[i].data.timeRes = parseInt(block.resMin)*60+parseInt(block.resSec)
      tmp[i].data.exResWeight = weight
      tmp[i].data.exResWeightGroup = weightgroup
      tmp[i].data.exResReps = reps
      tmp[i].data.exResRepsGroup = repsgroup

      tmp[i].data.exResRepsRounds = repsr
      tmp[i].data.exResWeightRounds = weightr

      //if(tmp[i].data.simple) {
        var notes = ''
        if(this.state.attendees.length > 0) {
          var ars = block.simpleNotes.split('|')
          for(var iss=0; iss<this.state.attendees.length; iss++) {
            if(iss === this.state.current) {
              if(block.simpleNote === '') {
                notes += '-|'
              } else {
                notes += block.simpleNote+'|'
              }
            } else {
              if(ars.length > iss) {
                if(ars[iss] !== '') {
                  notes += ars[iss]+'|'
                } else {
                  notes += '-|'
                }
              } else {
                notes += '-|'
              }
            }
          }
          notes = notes.substring(0, notes.length-1)
        } else {
          notes = block.simpleNote
        }
        tmp[i].data.notesResSimple = notes
      //}

      if(block.simple) {
        var a1 = "0"
        var a2 = "0"
        if(block.tmp1 != "") { a1 = block.tmp1}
        if(block.tmp2 != "") { a2 = block.tmp2}
        if(block.type === 0) {
          if(this.state.item.data.group) {
            if(tmp[i].data.amrapSimple !== undefined) {
              if(tmp[i].data.amrapSimple.length > this.state.current) {
                // do nothing
              } else {
                var tmpa1 = []
                for(var ai1=tmp[i].data.amrapSimple.length; ai1 < this.state.item.data.clients.length; ai1++) {
                  tmpa1.push("0+0")
                }
                tmp[i].data.amrapSimple = tmpa1
              }
            } else {
              var tmpa2 = []
              for(var ai2=0; ai2 < this.state.item.data.clients.length; ai2++) {
                tmpa2.push("0+0")
              }
              tmp[i].data.amrapSimple = tmpa2
            }
            tmp[i].data.amrapSimple[this.state.current] = a1+"+"+a2
          } else {
            tmp[i].data.amrapSimple = [a1+"+"+a2]
          }
        } else if(block.type !== 0 && block.type !== 5) {
          if(this.state.item.data.group) {
            if(tmp[i].data.valueSimple !== undefined) {
              if(tmp[i].data.valueSimple.length > this.state.current) {
                // do nothing
              } else {
                var tmpv1 = []
                for(var vi1=tmp[i].data.amrapSimple.length; vi1 < this.state.item.data.clients.length; vi1++) {
                  tmpv1.push(0)
                }
                tmp[i].data.valueSimple = tmpv1
              }
            } else {
              var tmpv2 = []
              for(var vi2=0; vi2 < this.state.item.data.clients.length; vi2++) {
                tmpv2.push(0)
              }
              tmp[i].data.valueSimple = tmpv2
            }
            tmp[i].data.valueSimple[this.state.current] = parseFloat(a1)
          } else {
            tmp[i].data.valueSimple = [parseFloat(a1)]
          }
        }

        if(this.state.item.data.group) {
          if(tmp[i].data.scaledSimple !== undefined) {
            if(tmp[i].data.scaledSimple.length > this.state.current) {
              // do nothing
            } else {
              var tmps1 = []
              for(var si1=tmp[i].data.scaledSimple.length; si1 < this.state.item.data.clients.length; si1++) {
                tmps1.push(false)
              }
              tmp[i].data.scaledSimple = tmps1
            }
          } else {
            var tmps2 = []
            for(var si2=0; si2 < this.state.item.data.clients.length; si2++) {
              tmps2.push(false)
            }
            tmp[i].data.scaledSimple = tmps2
          }
          tmp[i].data.scaledSimple[this.state.current] = block.scaledSimple
        } else {
          tmp[i].data.scaledSimple = [block.scaledSimple]
        }
      }

      Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.session+'/workout/'+this.state.program.id+'/blocks/'+tmp[i].id).update(
        tmp[i].data
      ).then((data)=>{
        EventEmitter.dispatch('showMessage', lang.t('messaging:message.resultsupdated'))
        setTimeout(() => {
          this.setState({
            changed: false
          })
        }, 100);
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
    
  }


  updateBest(ex) {
    var update = true
    var final = ex.resWeight
    var actual = ex.resWeight
    var per = 0
    var tmp = this.state.best
    var type = "kg"
    if(global.spaceLbs) {
        type = "lb"
    }
    if(ex.weightType !== "per") {
        type = ex.weightType
    }
    for(var item of tmp) {
      if(item.id === ex.id) {
        if(ex.tool !== 6 && ex.tool !== 7) {
          if(ex.weight > 0 && ex.weightType === 'per') {
            final = ex.resWeight*(100/ex.weight)
            per = ex.weight
          }
          
          var rval = item.data.value
          var rtype = ""
          if(item.data.type !== undefined) {
            rtype = item.data.type
          }
          if((rtype === "kg" && ex.weightType === "lb") || (rtype === "kg" && ex.weightType === "per" && global.spaceLbs)) {
            rval = item.data.value*global.lbsUp
          }
          if((rtype === "lb" && ex.weightType === "kg") || (rtype === "lb" && ex.weightType === "per" && !global.spaceLbs)) {
            rval = item.data.value*global.lbsDown
          }
          if(rval > final) {
            update = false
          }
        }
        if(item.data.value < ex.resWeight && (ex.tool === 6 || ex.tool === 7)) {
          update = false
        }
        if(update) {
          item.data.value = final
          this.setState({
            best: tmp
          })
        }
        
      }
      
    }
    var unit = ''
    if(ex.unit !== undefined) {
      unit = ex.unit
    }
    if(update && ex.tool !== 0 && ex.tool !== 9 && !isNaN(final)) {
      Firebase.database().ref('/records/'+this.state.user+'/'+ex.id).update({
        date: Moment(this.state.date, 'lll').format('DD/MM/YYYY HH:mm'),
        id: ex.id,
        name: ex.name,
        tool: ex.tool,
        value: final,
        actual: actual,
        percent: per,
        unit: unit,
        type: type
      })
    }
  }


  updateBestGroup(ex) {
    var update = true
    var final = 0
    var actual = 0
    var per = 0
    var id = this.state.attendees[this.state.current]
    var tmp = this.state.bestGroup
    var type = "kg"
    if(global.spaceLbs) {
        type = "lb"
    }
    if(ex.weightType !== "per") {
        type = ex.weightType
    }

    for(var client of global.clients) {
      if(client.id === this.state.attendees[this.state.current] && client.data.uid !== '') {
        id = client.data.uid
      }
    }
    if(ex.resWeightGroup !== undefined && ex.resWeightGroup.length > this.state.current) {
      final = parseFloat(ex.resWeightGroup[this.state.current])
      actual = parseFloat(ex.resWeightGroup[this.state.current])
      if(ex.weight > 0 && ex.weightType === 'per') {
        final = parseFloat(ex.resWeightGroup[this.state.current])*(100/ex.weight)
        per = ex.weight
      }
    }

    if(ex.tool === 0 && ex.tool === 9) {
      update = false
    }
    if(tmp !== null) {
      for(var cb of tmp) {
        if(cb.client === id && cb.id === ex.id) {
          var rval = cb.data.value
          var rtype = ""
          if(cb.data.type !== undefined) {
            rtype = cb.data.type
          }
          if((rtype === "kg" && ex.weightType === "lb") || (rtype === "kg" && ex.weightType === "per" && global.spaceLbs)) {
            rval = cb.data.value*global.lbsUp
          }
          if((rtype === "lb" && ex.weightType === "kg") || (rtype === "lb" && ex.weightType === "per" && !global.spaceLbs)) {
            rval = cb.data.value*global.lbsDown
          }
          if(rval > final || rval === final) {
            update = false
          }

          if(update) {
            cb.data.value = final
            this.setState({
              bestGroup: tmp
            })
          }
        }
      }
    }

    if(final === 0) {
      update = false
    }
    var unit = ''
    if(ex.unit !== undefined) {
      unit = ex.unit
    }
    if(update && ex.tool !== 0 && ex.tool !== 9 && !isNaN(final)) {
      Firebase.database().ref('/records/'+id+'/'+ex.id).update({
        //date: Moment().format('DD/MM/YYYY HH:mm'),
        date: Moment(this.state.date, 'lll').format('DD/MM/YYYY HH:mm'),
        id: ex.id,
        name: ex.name,
        tool: ex.tool,
        value: final,
        actual: actual,
        percent: per,
        unit: unit,
        type: type
      })
    }
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalLeader: false,
    })
  }



  // Display stuff ------------------------------------------------------------



  getSetTitle(block) {
    var s = ''
    if(block.rounds > 1) {
      s = 's'
    }
    var label = lang.t('program:label.set'+s)
    if(block.exercises.length > 1) {
      label = lang.t('program:label.dropset'+s)
      var id = ''
      for(var ex of block.exercises) {
        if(ex.id !== id && id !== '') {
          label = lang.t('program:label.superset'+s)
        }
      }
    }
    return label
  }


  renderBlockTypeStatic(item) {
    var label = ''
    var s = ''
    if(item.rounds > 1) {
      s = 's'
    }
    if(item.type === 0) {
      label = ' - '+HelperCal.getDurationMin(item.rounds)
    }
    if(item.type === 1) {
      label = ' - '+item.rounds+' '+lang.t('program:label.round'+s)
      if(item.emom) {
        label = ' - '+item.rounds+' '+lang.t('program:label.round'+s)+' '+lang.t('program:label.of')+' '+HelperCal.getDurationMin(parseInt(item.exercises[0].work))
      }
    }
    if(item.type === 2 || item.type === 3) {
      label = ' - '+item.rounds+' '+lang.t('program:label.rounds')
      if(item.rounds === 1) {
        label = ' - 1 '+lang.t('program:label.round')
      }
      if(item.type === 2 && item.emom) { 
        if(item.exercises[0].rest === 0) {
          label += ' '+lang.t('program:label.of')+' '+HelperCal.getDurationMin(parseInt(item.exercises[0].work))+' '+lang.t('program:label.worknorest')
        } else {
          label += ' '+lang.t('program:label.of')+' '+HelperCal.getDurationMin(parseInt(item.exercises[0].work))+' '+lang.t('program:label.workand')+' '+HelperCal.getDurationMin(parseInt(item.exercises[0].rest))+' '+lang.t('program:label.rest-s')
        }
      }
    }
    if(item.type === 4) {
      label = ' - '+item.rounds+' '+this.getSetTitle(item)
    }
    if(item.type === 5) {
      label = ' - '+HelperCal.getDurationMin(item.rounds)
      if(item.cycles > 1) {
        label = ' - '+item.cycles+' '+lang.t('program:label.rounds')+' - '+lang.t('program:label.cap')+': '+HelperCal.getDurationMin(item.rounds)
      }
    }
    return label
  }


  renderRepsTitleStatic(item, type, unit) {
    var label = lang.t('program:label.targetreps')
    if(type === 'result') {
      label = lang.t('program:label.totalrepsdone')
    }
    if(unit !== '' && unit !== undefined && unit !== 'reps') {
      if(unit === 'dist') {
        label = lang.t('program:label.targetdistance')
        if(type === 'result') {
          label = lang.t('program:label.distancedone')+' (m)'
        }
      }
      if(unit === 'cals') {
        label = lang.t('program:label.targetcalories')
        if(type === 'result') {
          label = lang.t('program:label.caloriesdone')+' (cal)'
        }
      }
      if(unit === 'time') {
        label = lang.t('program:label.targettime')
        if(type === 'result') {
          label = lang.t('program:label.timedone')+' (s)'
        }
      }
    } else {
      if(item === 6 || item === 7) {
        label = lang.t('program:label.targetdistance')
        if(type === 'result') {
          label = lang.t('program:label.distancedone')+' (m)'
        }
      }
    }
    return label
  }


  renderRepsStatic(item, num) {
    var label = item.reps
    var wlabel = ''
    if(item.reps === 0) {
      label = ''
    }

    if(item.weight !== 0 && item.weight !== 100 && item.weightType === 'per') {
      wlabel = ' '+lang.t('program:label.at')+' '+item.weight+'% '+lang.t('program:label.of1rm')
    }
    if(item.weight !== 0 && item.weight !== 100 && item.weightType !== 'per') {
      wlabel += ' '+lang.t('program:label.with')+' '+item.weight+' '+item.weightType
    }
    if(this.state.blocks[num].type === 1 || this.state.blocks[num].type === 5) {
      label = ''
      if(item.repsRoundStatic !== "" && item.repsRoundStatic !== "0") {
        label = item.repsRoundStatic.replaceAll('-', ' - ')
      }
      if(item.weightRoundStatic !== "" && item.weightRoundStatic !== '0') {
        if(item.weightType === 'kg') {
          wlabel = ' '+lang.t('program:label.with')+' '+item.weightRoundStatic.replaceAll('-', ' - ')+' kg'
        } else if(item.weightType === 'lb') {
          wlabel = ' '+lang.t('program:label.with')+' '+item.weightRoundStatic.replaceAll('-', ' - ')+' lb'
        } else {
          wlabel = ' '+lang.t('program:label.at')+' '+item.weightRoundStatic.replaceAll('-', ' - ')+' %'
        }
      }
    } 

    if(item.unit !== undefined && item.unit !== '') {
      if(item.unit === 'dist') {
        label += 'm'
      }
      if(item.unit === 'cals') {
        label += 'cal'
      }
      if(item.unit === 'time') {
        label += 's'
      }
    } else {
      if(item.tool === 6 || item.tool === 7) {
        label += 'm'
      }
      if(item.tool === 27) {
        label += 'cal'
      }
    }
    
    return [label, wlabel]
  }


  renderRepField(item, num, index) {
    if(this.state.blocks[num].rounds > 1 || this.state.blocks[num].cycles > 1) {
      return (
        <input type="text" className="light" value={item.resRepsRound} onChange={(val) => this.onChangeRepsRound(val, num, index)}/>
      )
    } else {
      return (
        <input type="number" className="light" value={item.resReps} onChange={(val) => this.onChangeReps(val, num, index)}/>
      )
    }
  }


  renderReps(item, num, index) {
    if(this.state.blocks[num].type !== 0) {
      if(this.state.isTraining) {
        return (
          <div className="col-4 info">
            <label>{this.renderRepsTitleStatic(item.tool, 'result', item.unit, item.name)}</label>
            <p>{item.resReps}</p>
          </div>
        )
      } else {
        return (
          <div className="col-4">
            <label>{this.renderRepsTitleStatic(item.tool, 'result', item.unit, item.name)}</label>
            {this.renderRepField(item, num, index)}
          </div>
        )
      }
    }
  }


  renderWeightField(item, num, index) {
    if(this.state.blocks[num].rounds > 1 || this.state.blocks[num].cycles > 1) {
      return (
        <input type="text" className="light" value={item.resWeightRound} onChange={(val) => this.onChangeWeightRound(val, num, index)}/>
      )
    } else {
      return (
        <input type="number" className="light" value={item.resWeight} onChange={(val) => this.onChangeWeight(val, num, index)}/>
      )
    }
  }


  renderStaticWeight(item) {
    var label = item.resWeight+' '+(global.spaceLbs ? 'lb' : 'kg')
    if(item.weightType !== 'per') {
      label = item.resWeight+' '+item.weightType
    }
    if(item.resWeightRound !== '') {
      var tmp = item.resWeightRound
      if(tmp.charAt(tmp.length-1) === '-') {
        tmp = item.resWeightRound.substring(0, item.resWeightRound.length-1)
      }
      label = tmp+' '+(global.spaceLbs ? 'lb' : 'kg')
      if(item.weightType !== 'per') {
        label = tmp+' '+item.weightType
      }
    }
    return label
  }


  renderWeight(item, num, index) {
    if(item.tool !== 0 && item.tool !== 6 && item.tool !== 7 && item.tool !== 9) {
      if(this.state.isTraining) {
        return (
          <div className="col-4 info">
            <label>{lang.t('program:form.weightused')}</label>
            <p>{this.renderStaticWeight(item)}</p>
          </div>
        )
      } else {
        return (
          <div className="col-4">
            <label>{lang.t('program:form.weightused')} ({item.weightType !== 'per' ? item.weightType : (global.spaceLbs ? 'lb' : 'kg')})</label>
            {this.renderWeightField(item, num, index)}
          </div>
        )
      }
    }
  }


  renderNotesStatic(item) {
    if(item.notes !== '') {
      return (
        <div className="clear" style={{paddingTop: 5}}>
          <span className="program-notes movement">{item.notes}</span>
        </div>
      )
    }
  }


  renderExerciseRest(block, index) {
    if(block.type === 2 && !block.emom && block.exercises[index].rest !== 0 && block.exercises[index].rest !== undefined && block.exercises[index].rest !== '') {
      return (
        <div className="box list simple mb-10">
          <div className="col-10">
            <div className="icon secondary lft mr-10">
              <div className="inner prog-rest"></div>
            </div>
            <p className="lft"><strong>{lang.t('program:label.rest')}</strong></p>
          </div>
          <div className="col-2 program-time">
            <p>{HelperCal.getDurationMin(parseInt(block.exercises[index].rest))}</p>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderTimeStatic(block, index) {
    if((block.type === 1 || block.type === 2) && !block.emom) {
      return (
        <div className="col-2 program-time">
          <p>{HelperCal.getDurationMin(parseInt(block.exercises[index].work))}</p>
        </div>
      )
    }
  }


  renderSimple(block, index) {
    if(block.results) {
      var height = ""
      if(!block.simple) {
        height = " pt-10"
      }
      return (
        <div className={"col-12 pb-10"+height}>
          <label>{lang.t('program:form.resultsnotes')}</label>
          <textarea value={block.simpleNote} onChange={(event) => this.setSimple(event, index)}></textarea>
        </div>
      )
    }
  }


  renderExercisesStatic(num) {
    var block = this.state.blocks[num]
    if(!block.simple) {
      return (
        <div>
          {block.exercises.map((item, index) => (
            <div key={index}>
              <div className="box list simple mb-10">
                <div className="col-10">
                  <div className="icon primary mr-10">
                    <div className={'inner '+global.exCatsIcons[block.cat]}></div>
                  </div>
                  <p className="lft"><strong>{this.renderRepsStatic(item, num)[0]} {item.name}</strong> {this.renderRepsStatic(item, num)[1]}</p>
                </div>
                {this.renderTimeStatic(block, index)}
                <div className="clear"></div>
                <div className={block.results ? 'program-results' : 'none'}>
                  {this.renderReps(item, num, index)}
                  {this.renderWeight(item, num, index)}
                  {this.renderNotesStatic(item)}
                  <div className="clear"></div>
                </div>
                <div className="clear"></div>
              </div>
              {this.renderExerciseRest(block, index)}
            </div>
          ))}
        </div>
      )
    }
  }


  renderSessionNotes(item, index) {
    if(this.state.isTraining) {
      if(item.resNotes !== '') {
        return (
          <p className="small mb-20 pl-10" style={{whiteSpace: 'pre-wrap'}}><strong>Session notes</strong><br/>{item.resNotes}</p>
        )
      }
    } else {
      return (
        <div>
          <button className={item.show ? 'width-12 none' : 'width-12 btn tertiary small add'} onClick={() => this.toggleNotes(index)}>{lang.t('program:button.showsessionnotes')}</button>
          <div className={item.show ? 'notes' : 'notes none'}>
            <button className="width-12 btn tertiary small minus" onClick={() => this.toggleNotes(index)}>{lang.t('program:button.hidesessionnotes')}</button>
            <div className="sv-10"></div>
            <textarea value={item.resNotes} onChange={(event) => this.setNotes(event, index)}></textarea>
          </div>
        </div>
      )
    }
  }


  renderStaticNotes(item) {
    if(item.notes !== '') {
      return <p className={item.simple ? 'mb-20 pl-10' : 'small mb-20 pl-10'} style={{whiteSpace: 'pre-wrap'}}>{item.notes}</p>
    }
  }


  renderTime(item, index) {
    if(item.type === 5) {
      if(this.state.isTraining) {
        return (
          <div className="info">
            <label>{lang.t('program:form.completiontime')}</label>
            <p>{HelperCal.getDurationMin(item.resMin*60+item.resSec)}</p>
          </div>
        )
      } else {
        if(this.state.attendees.length === 0) {
          return (
            <div className="mb-20">
              <div className="form-item col-3">
                <label>{lang.t('program:form.completiontime')}</label>
                <input type="text" value={item.resMin} onChange={event => this.setTimeMin(event, index)}/>
              </div>
              <div className="col-3">
                <label>&nbsp;</label>
                <input type="text" value={item.resSec} onChange={event => this.setTimeSec(event, index)}/>
              </div>
              <div className="clear"></div>
            </div>
          )
        } else {
          return (
            <div className="mb-20">
              <div className="col-3">
                <label>{lang.t('program:form.completiontime')}</label>
                <input type="text" value={item.resMinGroup[this.state.current]} onChange={event => this.setTimeMin(event, index)}/>
              </div>
              <div className="col-3">
                <label>&nbsp;</label>
                <input type="text" value={item.resSecGroup[this.state.current]} onChange={event => this.setTimeSec(event, index)}/>
              </div>
              <div className="clear"></div>
            </div>
          )
        }
      }
    } else if(item.type === 0) {
      return (
        <div className="mb-20">
          <div className="col-3">
            <label>{lang.t('program:form.finishedrounds')}</label> 
            <input type="number" className="small" value={item.tmp1} onChange={(val) => this.setAR1(val, index)}/>
          </div>
          <div className="col-3">
            <label>{lang.t('program:form.reps')}</label>
            <input type="number" className="small" value={item.tmp2} onChange={(val) => this.setAR2(val, index)}/>
          </div>
          <div className="clear"></div>
        </div>
      )
    } else {
      if(item.simple) {
        return (
          <div className="mb-20">
            <div className="col-3">
              <label>{lang.t('program:form.result'+item.unitSimple)}</label> 
              <input type="number" className="small" value={item.tmp1} onChange={(val) => this.setAR1(val, index)}/>
            </div>
            <div className="clear"></div>
          </div>
        )
      }
    }
  }


  renderScaled(block, index) {
    if(block.simple) {
      return (
        <div className="col-12 clear">
          <InputToggle label='' value={block.scaledSimple} onChange={(event) => this.updateScaled(index, event)} text={'Scaled'}/>
        </div>
      )
    }
  }


  renderStaticRest(block) {
    if(block.type === 4 && block.exercises[0].work !== 0 && block.exercises[0].work !== undefined) {
      return (
        <div className="box list simple mb-10">
          <div className="col-10">
          <div className="icon secondary lft mr-10">
              <div className="inner prog-rest"></div>
            </div>
            <p className="lft"><strong>{lang.t('program:label.rest')}</strong></p>
          </div>
          <div className="col-2 program-time">
            <p>{HelperCal.getDurationMin(parseInt(block.exercises[0].work))}</p>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderBlockTitleStatic(item) {
    if(item.name !== '' && item.name !== undefined) {
      return item.name
    } else {
      return lang.t(global.exCats[item.cat])
    }
  }


  renderBlockLeaderboard(item) {
    if(item.results && global.showHabits && !this.state.isTraining) {
      return <button className="btn tertiary small rgt" onClick={() => this.setState({showModalLeader: true, leaderBlock: item})}>Leaderboard</button>
    }
  }


  renderBlocksStatic() {
    return (
      <div>
        {this.state.blocks.map((item, index) => (
          <div key={index}>
            <div className="clear mb-50">
              <label>{this.renderBlockTitleStatic(item)}</label>
              <h3 className="clear lft mb-20">{lang.t(global.proTypes[item.type])} {this.renderBlockTypeStatic(item)}</h3>
              {this.renderBlockLeaderboard(item)}
              <div className="clear"></div>
              {this.renderTime(item, index)}
              {this.renderScaled(item, index)}
              {this.renderExercisesStatic(index)}
              {this.renderStaticRest(item)}
              {this.renderStaticNotes(item)}
              {this.renderSimple(item, index)}
              {this.renderSessionNotes(item, index)}
            </div>
          </div>
        ))}
      </div>
    )
  }


  renderButtonTop() {
    if(this.state.changed) {
      return (
        <div className="program-float">
          <p className="mb-10">There are unsaved changes</p>
          <button className="btn tertiary small width-12" onClick={() => this.updateResults()}>{lang.t('program:button.updateresults')}</button>
        </div>
      )
    }
  }


  renderButton() {
    if(this.state.changed) {
      return (
        <div>
          <div className="width-6">
            <button className="btn primary" onClick={() => this.updateResults()}>{lang.t('program:button.updateresults')}</button>
          </div>
          <div className="sv-40"></div>
        </div>
      )
    }
  }


  renderHeaderSelect() {
    if(this.state.attendees.length > 0) {
      return (
        <div className="rgt">
          <label className="lft mr-10">Client</label>
          <select value={this.state.current} className="lft light large" onChange={(event) => this.changeClient(event.target.value)}>
            {this.state.attendees.map((item, index) => (
              <option value={index} key={item}>{this.getClientName(item)}</option>
            ))}
          </select>
        </div>
      )
    }
  }


  renderBenchmark() {
    if(this.state.program.data.benchmark) {
      return (
        <div className="infobox large green mb-20">
          <p>{lang.t('program:label.thisisabenchmarkprogram')}</p>
        </div>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('assistant,assistant2,trainer')) {
      if(this.state.program === null) {
        return (
          <div></div>
        )
      } else {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content form-sidebar mt-20">
              {this.renderBenchmark()}
              <div className="program-header mb-60">
                <div className={'icon large lft mr-20 '+HelperCal.getColor(this.state.program.data.time)}>
                  <p>{this.state.program.data.time}'</p>
                </div>
                <div className="main">
                  <h2>{this.state.program.data.name}</h2>
                  <p className="mb-10">{this.state.program.data.desc}</p>
                </div>
                <div className="clear"></div>
              </div>
              {this.renderBlocksStatic()}
              <div className="clear sv-30"></div>
              {this.renderButton()}
            </div>
            <div className="header">
              <div className="content">
                <Link to={this.state.attendees.length > 0 ? '/calendar/group-session/'+this.state.session : '/calendar/session/'+this.state.session} className="back lft mr-10">
                  <div className="circle"></div>
                  <div className="arrow"></div>
                </Link>
                <h2 className="lft">{this.state.isTraining ? lang.t('program:title.trainingresults') : lang.t('program:title.sessionresults')} - {this.state.date}</h2>
                {this.renderHeaderSelect()}
                <div className="clear"></div>
              </div>
            </div>
            <Navigation active='calendar' />
            {this.renderButtonTop()}
            <ModalLeaderboard show={this.state.showModalLeader} type={'notes'} session={this.state.item} block={this.state.leaderBlock} onHide={() => this.hideModals()}/>
          </div>
        )
      }
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='results-locked' type='simple'/>
          </div>
          <Header title={lang.t('program:title.sessionresults')} link={ROUTES.CALENDAR}/>
          <Navigation active='calendar' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['program','common','header','messaging'])(withRouter(withAuthorization(condition)(ResultsPage)));