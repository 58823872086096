import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import 'moment/locale/es';
import Connector from '../../data/Connector';
import HelperBusiness from '../../helper/Business';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import ListEmpty from '../../components/List/empty';
import InputClient from '../../components/Form/client';
import InputDate from '../../components/Form/input-date';
import InputToggle from '../../components/Form/toggle';
import InfoPrice from '../../components/Form/price';
import InputRadio from '../../components/Form/radio';
import FormCard from '../../components/Form/card';
import Loader from '../../components/Form/loader';
import WidgetFormInfo from '../../components/Widgets/forminfo';
import WidgetCard from '../../components/Widgets/card';
import ModalClients from '../../components/Modals/clients';
import ModalMessageSimple from '../../components/Modals/message-simple';
import ModalMessageDouble from '../../components/Modals/message-double';
import ModalBilling from '../../components/Modals/billing';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class DebitNewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      clients: global.clients,
      clientsList: global.clients,
      products: [],
      product: null,
      productId: '',
      client: null,
      group: false,
      stype: '11',
      log: global.userLog,
      date: Moment().add(1, 'day').format('X'),
      loading: false,
      method: 'card',
      card: false,
      valuesCard: ['', '', '', '', ''],
      errorCard: [false, false, false, false, false],
      search: '',
      error: false,
      token: '',
      cancels: 'notset',
      cdate: Moment().add(90, 'days').format('X'),
      family: '',
      back: global.currentPage,
      showModalClients: false,
      showModalConfirm: false,
      showModalWarning: false,
      showModalInfo: false,
      showModalStock: false,
      subscriptionList: [],
    };
  }


  componentDidMount() {
    HelperPage.initializeForm('New Membership - PT Mate')
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => {
      this.configureData()
      if(this.state.loading) {
        global.message = lang.t('messaging:message.membershipcreated')
        this.setState({
          loading: false
        })
        setTimeout(() => {
          EventEmitter.dispatch('updateBadges', 'payment');
        }, 1000);
        this.props.history.push(this.state.back)
      }
    })
    EventEmitter.subscribe('logLoaded', (event) => this.configureLog())
    EventEmitter.subscribe('productsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('tokenLoaded', (event) => this.setState({token: global.clientToken}))
    this.configureData()

    if(global.currentClient !== '' && global.currentClient !== undefined) {
      for(var item of global.clients) {
        if(item.id === global.currentClient) {
          this.setState({
            client: item
          })
        }
      }
    }
  }


  componentWillUnmount() {
    this._isMounted = false
    this.setState({
      loading: false
    })
  }


  configureData() {
    if(this._isMounted) {
      var tmp = []
      var tmp2 = []
      var back = ROUTES.OVERVIEW
      if(global.currentPage !== '') {
        back = global.currentPage
      }
      if(global.linkCli !== '') {
        back = global.linkCli
      }
      for(var item of global.products) {
        if(item.data.type === 'subscription') {
          tmp.push(item)
        }
      }
      for(var item2 of global.clients) {
        if(item2.data.parent === undefined || item2.data.parent === '') {
          tmp2.push(item2)
        }
      }
      var method = 'card'
      if(global.userStripeConnect === '' || global.userStripeConnect === undefined) {
        method = 'other'
      }
      this.loadSubscriptionList()

      this.setState({
        clients: global.clients,
        clientsList: tmp2,
        products: tmp,
        product: null,
        productId: '',
        back: back,
        method: method
      })
    }
  }


  loadSubscriptionList() {
    var list = []
    let itemsRef = Firebase.database().ref('/admin/');
    itemsRef.on("value", function(snapshot) {
      if (snapshot.exists()) {
        var data = snapshot.val();
        list = data.subscriptions
        if(data.subscriptions === undefined) {
          list = []
        }
      }
    });
    this.setState({
      subscriptionList: list
    })
  }


  configureLog() {
    if(this._isMounted) {
      this.setState({
        log: global.userLog
      }, () => {
        if(this.state.loading) {
          for(var item of this.state.log) {
            if(item.data.title === 'debiterror') {
              EventEmitter.dispatch('showMessageError', item.data.message);
              Firebase.database().ref('/log/'+global.uid+'/'+item.id).remove()
              this.setState({
                loading: false,
                error: true
              })
            }
          }
        }
      })
    }
  }



  // Interactive ------------------------------------------------------------



  checkCreateDebit() {
    this.setState({errorCard: [false, false, false, false, false]})
    var tmp = [false, false, false, false, false]
    var passed = true
    if(this.state.valuesCard[0] === '') {
      tmp[0] = true
      passed = false
    }
    if(this.state.valuesCard[1] === '') {
      tmp[1] = true
      passed = false;
    }
    if(this.state.valuesCard[2] === '') {
      tmp[2] = true
      passed = false;
    }
    if(this.state.valuesCard[3] === '') {
      tmp[3] = true
      passed = false;
    }
    if(this.state.valuesCard[4] === '') {
      tmp[4] = true
      passed = false;
    }
    this.setState({errorCard: tmp})

    var stock = -1
    if(this.state.product.data.stock !== undefined && this.state.product.data.stock !== -1) {
      stock = this.state.product.data.stock-1
    }

    if(stock !== 0) {
      if(passed) {
        // Execute payment
        this.setState({
          loading: true,
        })
        var group = 'no'
        if(this.state.product.data.stype === 'group') {
          group = 'yes'
        } else if(this.state.product.data.stype === 'both') {
          group = 'both'
        }

        var sessions = 0
        if(this.state.product.data.sessions !== undefined) {
          sessions = this.state.product.data.sessions
        }
        var sessions11 = 0
        if(this.state.product.data.sessions11 !== undefined) {
          sessions11 = this.state.product.data.sessions11
        }

        var email = this.state.client.data.email
        if(email === '' || email === undefined) {
          email = 'noemail@ptmate.net'
        }

        var ds1 = Moment(this.state.date, 'X').format('DD/MM/YYYY')
        var date = Moment(ds1+' 01:30', 'DD/MM/YYYY HH:mm').format('X')

        if(Moment(date, 'X') < Moment()) {
          var start = Moment().add(1, 'day').format('DD/MM/YYYY')
          date = Moment(start+' 01:30', 'DD/MM/YYYY HH:mm').format('X')
        }

        var cdate = this.state.cdate
        if(Moment(cdate, 'X') < Moment(date, 'X')) {
          cdate = Moment(date, 'X').add(1, 'days').format('X')
        }
        var ds2 = Moment(cdate, 'X').format('DD/MM/YYYY')
        cdate = Moment(ds2+' 01:30', 'DD/MM/YYYY HH:mm').format('X')

        var count = 1
        if(this.state.product.data.interval !== undefined) {
          count = this.state.product.data.interval
        }
        if(this.state.product.data.billing === 'fortnight') {
          count = 2
        }

        if(this.state.client.data.cardId !== '' && this.state.client.data.cardId !== undefined) {
          var callFunction3 = Firebase.functions().httpsCallable('connectedManageClientCardV2');
          callFunction3({type: 'delete', account: global.userStripeConnect, customer: this.state.client.data.customer, card: this.state.client.data.cardId, client: this.state.client.id, uid: global.uid}).then(function(result) {});
        }

        if(this.state.client.data.customer !== '' && this.state.client.data.customer !== undefined) {
          // Create card only
          var callFunction = Firebase.functions().httpsCallable('connectedCreateSubscriptionV2');
          callFunction({type: 'card', account: global.userStripeConnect, name: this.state.valuesCard[0], card: this.state.valuesCard[1], month: this.state.valuesCard[2], year: this.state.valuesCard[3], cvc: this.state.valuesCard[4], customer: this.state.client.data.customer, client: this.state.client.id, product: this.state.product.data.product, plan: this.state.product.data.sku, planname: this.state.product.data.name, price: parseInt(this.state.product.data.price*100), billing: this.state.product.data.billing, date: Moment().format('DD/MM/YYYY HH:mm'), group: group, start: parseInt(date), trial: 'set', sessions: sessions, cancels: this.state.cancels, end: parseInt(cdate), sessions11: sessions11, user: this.state.family, uid: global.uid, stock: stock, count: count}).then(function(result) {});
        } else {
          // Customer and card
          var callFunction2 = Firebase.functions().httpsCallable('connectedCreateSubscriptionV2');
          callFunction2({type: 'account', account: global.userStripeConnect, clientname: this.state.client.data.name, email: email, name: this.state.valuesCard[0], card: this.state.valuesCard[1], month: this.state.valuesCard[2], year: this.state.valuesCard[3], cvc: this.state.valuesCard[4], client: this.state.client.id, product: this.state.product.data.product, plan: this.state.product.data.sku, planname: this.state.product.data.name, price: parseInt(this.state.product.data.price*100), billing: this.state.product.data.billing, date: Moment().format('DD/MM/YYYY HH:mm'), group: group, start: parseInt(date), trial: 'set', sessions: sessions, cancels: this.state.cancels, end: parseInt(cdate), sessions11: sessions11, user: this.state.family, uid: global.uid, stock: stock, count: count}).then(function(result) {
            //var sanitizedMessage = result.data.text;
          });
        }

        this.getPaymentFailsafe()
      }
    } else {
      this.setState({
        showModalStock: true
      })
    }
  }


  createDebit() {
    this.hideModals()

    var stock = -1
    if(this.state.product.data.stock !== undefined && this.state.product.data.stock !== -1) {
      stock = this.state.product.data.stock-1
    }

    if(stock !== 0) {

      this.setState({
        loading: true,
      })
      var group = 'no'
      if(this.state.product.data.stype === 'group') {
        group = 'yes'
      } else if(this.state.product.data.stype === 'both') {
        group = 'both'
      }
      var sessions = 0
      if(this.state.product.data.sessions !== undefined) {
        sessions = this.state.product.data.sessions
      }
      var sessions11 = 0
      if(this.state.product.data.sessions11 !== undefined) {
        sessions11 = this.state.product.data.sessions11
      }

      var ds1 = Moment(this.state.date, 'X').format('DD/MM/YYYY')
      var date = Moment(ds1+' 01:30', 'DD/MM/YYYY HH:mm').format('X')

      if(Moment(date, 'X') < Moment()) {
        var start = Moment().add(1, 'day').format('DD/MM/YYYY')
        date = Moment(start+' 01:30', 'DD/MM/YYYY HH:mm').format('X')
      }

      var cdate = this.state.cdate
      if(Moment(cdate, 'X') < Moment(date, 'X')) {
        cdate = Moment(date, 'X').add(1, 'days').format('X')
      }
      var ds2 = Moment(cdate, 'X').format('DD/MM/YYYY')
      cdate = Moment(ds2+' 01:30', 'DD/MM/YYYY HH:mm').format('X')
      global.badgeLocked = true

      var count = 1
      if(this.state.product.data.interval !== undefined) {
        count = this.state.product.data.interval
      }
      if(this.state.product.data.billing === 'fortnight') {
        count = 2
      }

      var callFunction = Firebase.functions().httpsCallable('connectedCreateSubscriptionV2');
      callFunction({type: 'create', account: global.userStripeConnect, customer: this.state.client.data.customer, client: this.state.client.id, product: this.state.product.id, plan: this.state.product.data.sku, planname: this.state.product.data.name, price: this.state.product.data.price*100, billing: this.state.product.data.billing, date: Moment().format('DD/MM/YYYY HH:mm'), group: group, start: parseInt(date), trial: 'set', sessions: sessions, cancels: this.state.cancels, end: parseInt(cdate), sessions11: sessions11, user: this.state.family, uid: global.uid, stock: stock, count: count}).then(function(result) {
        //var sanitizedMessage = result.data.text;
      });

      this.getPaymentFailsafe()

    } else {
      this.setState({
        showModalStock: true
      })
    }
  }


  getPaymentFailsafe() {
    setTimeout(() => {
      if(this.state.client !== null) {
        if(this.state.client.id !== undefined && this.state.client.data.customer !== undefined) {
          var callFunction = Firebase.functions().httpsCallable('chargeBackupV2');
          callFunction({type: 'payment', account: global.userStripeConnect, customer: this.state.client.data.customer, client: this.state.client.id, uid: global.uid}).then(function(result) {});
        }
      }
    }, 100000);
  }


  // Non Stripe setup

  createOther() {
    var stock = -1
    if(this.state.product.data.stock !== undefined && this.state.product.data.stock !== -1) {
      stock = this.state.product.data.stock-1
    }
    var group = false
    if(this.state.product.data.stype === 'group') {
      group = true
    }

    var sessions = 0
    if(this.state.product.data.sessions !== undefined) {
      sessions = this.state.product.data.sessions
    }
    var sessions11 = 0
    if(this.state.product.data.sessions11 !== undefined) {
      sessions11 = this.state.product.data.sessions11
    }

    var ds1 = Moment(this.state.date, 'X').format('DD/MM/YYYY')
    var date = Moment(ds1+' 01:30', 'DD/MM/YYYY HH:mm').format('X')

    if(Moment(date, 'X') < Moment()) {
      var start = Moment().add(1, 'day').format('DD/MM/YYYY')
      date = Moment(start+' 01:30', 'DD/MM/YYYY HH:mm').format('X')
    }

    var cdate = this.state.cdate
    if(Moment(cdate, 'X') < Moment(date, 'X')) {
      cdate = Moment(date, 'X').add(1, 'days').format('X')
    }
    var ds2 = Moment(cdate, 'X').format('DD/MM/YYYY')
    cdate = Moment(ds2+' 01:30', 'DD/MM/YYYY HH:mm').format('X')

    if(this.state.cancels !== 'set') {
      cdate = null
    }

    var count = 1
    if(this.state.product.data.interval !== undefined) {
      count = this.state.product.data.interval
    }
    if(this.state.product.data.billing === 'fortnight') {
      count = 2
    }

    var next = Moment(date, 'X').add(count, 'weeks').format('X')
    if(this.state.product.data.billing === 'month') {
      next = Moment(date, 'X').add(count, 'months').format('X')
    }
    if(this.state.product.data.billing === 'fortnight') {
      count = 2
      next = Moment(date, 'X').add(count, 'months').format('X')
    }
    var is11 = false
    if(this.state.product.data.stype === 'both') {
      is11 = true
      group = true
    }
    var pid = this.state.product.id

    if(stock !== 0) {
      var obj = Firebase.database().ref('/clients/'+global.uid+'/'+this.state.client.id+'/subscriptions/').push()
      obj.update({
        account: this.state.family,
        billing: this.state.product.data.billing,
        interval: count,
        date: Moment().format('DD/MM/YYYY HH:mm'),
        done: 0,
        done11: 0,
        group: group,
        id: obj.key,
        is11: is11,
        name: this.state.product.data.name,
        next: parseInt(date),
        price: this.state.product.data.price*100,
        start: parseInt(date),
        product: 'other',
        sessions: sessions,
        sessions11: sessions11,
        status: 'trialing',
        end: cdate,
      }).then(() => {
        global.message = lang.t('messaging:message.membershipcreated')
        var tmp = this.state.subscriptionList
        tmp.push(global.uid+'||'+this.state.client.id+'||'+obj.key)
        Firebase.database().ref('/admin/').update({
          subscriptions: tmp
        })
        if(stock !== -1) {
          Firebase.database().ref('/packs/'+global.uid+'/'+pid).update({
            stock: stock
          })
        }
        this.props.history.push(this.state.back)
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    } else {
      this.setState({
        showModalStock: true
      })
    }
  }


  // Interaction

  updateValues(val, key) {
    this.setState({
      [key]: val
    });
  }


  selectProduct(event) {
    var tmp = null
    for(var item of this.state.products) {
      if(item.id === event.target.value) {
        tmp = item
      }
    }
    if(this.state.client !== null) {
      var card = true
      if(this.state.client.data.cardId !== '' && this.state.client.data.cardId !== undefined) {
        card = false
      }
      this.setState({
        card: card
      });
    }
    this.setState({
        productId: event.target.value,
        product: tmp
    });
  }


  selectFamily(event) {
    this.setState({
      family: event.target.value
    });
  }


  getFamilyName(id) {
    var label = 'Client'
    for(var item of global.clients) {
      if(item.id === id) {
        label = item.data.name
      }
    }
    return label
  }


  setCancels() {
    var tmp = 'set'
    if(this.state.cancels === 'set') {
      tmp = 'notset'
    }
    this.setState({
      cancels: tmp
    })
  }



  // Modals ------------------------------------------------------------



  checkModalConfirm() {
    if(this.state.product.data.stock !== 0) {
      var short = false
      if(this.state.cancels === 'set') {
        var edate = Moment().add(7, 'days')
        if(this.state.product.billing === 'fortnight') {
          edate = Moment().add(14, 'days')
        } else if(this.state.product.billing === 'month') {
          edate = Moment().add(1, 'month')
        }
        if(Moment(this.state.cdate, 'X') < edate) {
          short = true
        }
      }

      if(short) {
        this.setState({
          showModalWarning: true
        })
      } else {
        this.setState({
          showModalConfirm: true
        })
      }
    } else {
      this.setState({
        showModalStock: true
      })
    }
  }

  
  hideModals() {
    this.setState({
      showModalClients: false,
      showModalWarning: false,
      showModalConfirm: false,
      showModalInfo: false,
      showModalStock: false,
    })
  }


  modalClick(item) {
    this.hideModals()
    var card = false
    if(item.data.cardId === '' || item.data.cardId === undefined) {
      card = true
    }
    this.setState({
      client: item,
      card: card
    })
    if(item.data.uid !== '') {
      Connector.getClientToken(item.data.uid)
    }
  }



  // Display stuff ------------------------------------------------------------



  renderProrata() {
    if(this.state.product !== null) {
      var diff = Moment(this.state.cdate, 'X').diff(Moment(this.state.date, 'X'), 'days')+1
      var base = 7
      var show = false
      if(this.state.product.data.billing === 'fortnight') {
        base = 14
      }
      if(this.state.product.data.interval !== undefined) {
        base = this.state.product.data.interval*7
      }
      var date = Moment()
      if(diff % base !== 0 && this.state.product.data.billing !== 'month') {
        show = true
        var num = parseInt(diff/base)*base+base
        date = Moment(this.state.date, 'X').add(num, 'days')
      }
      if(this.state.product.data.billing === 'month') {
        if(Moment(this.state.date, 'X').format('DD') !== Moment(this.state.cdate, 'X').format('DD')) {
          show = true
          diff = Moment(this.state.cdate, 'X').diff(Moment(this.state.date, 'X'), 'months')+1
          date = Moment(this.state.date, 'X').add(diff, 'months')
        }
      }
      if(show && this.state.cancels === 'set' && this.state.method === 'card') {
        return (
          <div className="infobox yellow mb-20">
            <p><strong>{lang.t('billing:form.alert.title')}:</strong> {lang.t('billing:form.alert.text1')} {global.curSym}{this.state.product.data.price.toFixed(2)}. {lang.t('billing:form.alert.text2')} {Moment(date).locale(lang.language).format('D MMM YYYY')}.<br/><button className="btn tertiary small" onClick={() => this.setState({showModalInfo: true})}>{lang.t('billing:button.learnmore')}</button></p>
          </div>
        )
      }
    }
  }

  
  renderCancels() {
    if(this.state.cancels === 'set') {
      return <InputDate label="" value={Moment(this.state.cdate, 'X').format('YYYY-MM-DD')} format='YYYY-MM-DD' min={Moment().add(1, 'days').format('YYYY-MM-DD')} max={Moment().add(24, 'months').format('YYYY-MM-DD')} onChange={(event) => this.updateValues(Moment(event).format('X'), 'cdate')}/>
    }
  }


  renderFamily() {
    if(this.state.client !== null && this.state.product !== null) {
      if(this.state.client.data.linked !== undefined) {
        return (
          <div className="form-item">
            <label>{lang.t('billing:form.familymember')}</label>
            <select onChange={event => this.selectFamily(event)} value={this.state.family}>
              <option value=''>{lang.t('billing:label.family')}</option>
              <option value={this.state.client.id} key={this.state.client.id}>{this.state.client.data.name}</option>
              {this.state.client.data.linked.map(item => (
                <option value={item} key={item}>{this.getFamilyName(item)}</option>
              ))}
            </select>
          </div>
        )
      }
    }
  }


  renderBottom() {
    if(this.state.productId !== '' && this.state.client !== null) {
      if(this.state.method === 'card') {
        if(this.state.card) {
          return (
            <div>
              <div className="sv-20"></div>
              <FormCard values={this.state.valuesCard} error={this.state.errorCard} onChange={(event) => this.setState({valuesCard: event})}/>
              <div className="clear sv-50"></div>
              <button className="btn primary mb-10" onClick={() => this.checkCreateDebit()}>{lang.t('billing:button.createmembership')}</button>
              <p className="small center secondary">{lang.t('billing:form.cardsaved')}<br/>{lang.t('billing:form.paymentfee')}: {global.stripeFee}</p>
            </div>
          )
        } else {
          return (
            <div>
              <div className="sv-20"></div>
              <div className="paymethod mb-30">
                <WidgetCard id={this.state.client.id}/>
                <p style={{whiteSpace: 'pre-wrap'}}>{lang.t('billing:form.info.existingcard2')}</p>
              </div>
              <button className="btn tertiary add width-12" onClick={() => this.setState({card: true})}>{lang.t('billing:button.useanothercard')}</button>
              <div className="sv-60"></div>
              <button className="btn primary" onClick={() => this.checkModalConfirm()}>{lang.t('billing:button.createmembership')}</button>
              <div className="sv-10"></div>
              <p className="small center secondary">{lang.t('billing:form.paymentfee')}: {global.stripeFee}</p>
            </div>
          )
        }
      } else {
        return (
          <div>
            <div className="paymethod mt-10">
              <p>{lang.t('billing:form.info.othersub')}</p>
            </div>
            <div className="sv-50"></div>
            <button className="btn primary mb-10" onClick={() => this.createOther()}>{lang.t('billing:button.createmembership')}</button>
          </div>
        )
      }
    }
  }


  renderPaymentMethod() {
    if(this.state.productId !== '' && this.state.client !== null && global.userStripeConnect !== '' && global.userStripeConnect !== undefined) {
      return (
        <InputRadio
          value={this.state.method}
          clickElement={(event) => this.setState({method: event})}
          values={[{name: lang.t('billing:form.cardpayment'), value: 'card'}, {name: lang.t('billing:form.othermethod'), value: 'cash'}]}
        />
      )
    }
  }


  renderError() {
    if(this.state.error) {
      return (
        <p className="center" style={{fontSize: '12px', opacity: 0.6}}><strong>{lang.t('billing:error.membership.title')}:</strong><br/>{lang.t('billing:error.membership.text')}</p>
      )
    }
  }


  renderStripeFee() {
    if(this.state.method === 'card' && this.state.productId !== '' && this.state.client !== null) {
      return (
        <div className="infobox mb-20">
          <h5>{lang.t('billing:form.stripefee.title')}</h5>
          <p style={{whiteSpace: 'pre-wrap'}}>{lang.t('billing:form.stripefee.text')}</p>
        </div>
      )
    }
  }


  renderExpiryInfo() {
    if(this.state.product !== null) {
      if(this.state.product.data.sessions !== 0) {
        return (
          <div className="infobox mb-20">
            <h5>{lang.t('billing:label.reset.title')}</h5>
            <p style={{whiteSpace: 'pre-wrap'}}>{lang.t('billing:label.reset.text1')} {this.state.product.data.sessions-1} {lang.t('billing:label.reset.text2')} {this.state.product.data.sessions} {lang.t('billing:label.reset.text3')} {this.state.product.data.sessions} {lang.t('billing:label.reset.text4')} {this.state.product.data.sessions+1}.</p>
          </div>
        )
      }
    }
  }


  renderDebitType(item) {
    var label = lang.t('billing:label.formgroup')
    if(item.data.stype === '11') {
      label = lang.t('billing:label.form11')
    }
    if(item.data.stype === 'both') {
      label = lang.t('billing:label.formgroup11')
    }
    return label
  }


  renderSidebar() {
    if(global.userStripeConnect !== undefined && global.userStripeConnect !== '') {
      return (
        <div className="sidebar">
          <WidgetFormInfo id='directdebit'/>
          {this.renderExpiryInfo()}
          {this.renderStripeFee()}
        </div>
      )
    } else {
      return (
        <div className="sidebar">
          <WidgetFormInfo id='directdebitmanual'/>
          {this.renderExpiryInfo()}
        </div>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('admin')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content form-sidebar">
            <div className="col-6 mt-20">
              <div className="box">
                {this.renderProrata()}
                <InputClient id='debit' client={this.state.client} clickElement={() => this.setState({showModalClients: true})}/>
                <div className="sv-20"></div>
                <label>{lang.t('billing:form.product')}</label>
                <select onChange={event => this.selectProduct(event)} value={this.state.productId}>
                  <option value={''}>- {lang.t('billing:form.pleaseselect')} -</option>
                  {this.state.products.map(item => (
                    <option value={item.id} key={item.id}>{item.data.name} {this.renderDebitType(item)}</option>
                  ))}
                </select>
                <div className="sv-20"></div>
                {this.renderFamily()}
                <InputDate label={this.state.method === 'card' ? lang.t('billing:form.firstcharge') : lang.t('billing:form.firstcharge2')} value={Moment(this.state.date, 'X').format('YYYY-MM-DD')} format='YYYY-MM-DD' min={Moment().add(1, 'day').format('YYYY-MM-DD')} max={Moment().add(3, 'months').format('YYYY-MM-DD')} onChange={(event) => this.updateValues(Moment(event).format('X'), 'date')}/>
                <InputToggle label={lang.t('billing:form.enddate')} value={this.state.cancels === 'set' ? true : false} onChange={() => this.setCancels()} text={lang.t('billing:form.enddate.text')}/>
                {this.renderCancels()}
                <div className="sv-10"></div>
                <InfoPrice id={this.state.productId} product={this.state.product} type={this.state.client === null ? 'client' : 'debit'}/>
                {this.renderPaymentMethod()}
                {this.renderBottom()}
              </div>
            </div>
            <div className="col-6 mt-20">
              {this.renderSidebar()}
            </div>
            <div className="clear sv-40"></div>
  
            {this.renderError()}
          </div>
          <Header title={lang.t('billing:title.newmembership')} link={this.state.back}/>
          <Navigation active='billing' />
          <ModalClients clients={this.state.clientsList} show={this.state.showModalClients} onHide={() => this.hideModals()} clickElement={(event) => this.modalClick(event)}/>
          <ModalMessageSimple type='confirmdebit' show={this.state.showModalConfirm} var={this.state.client === null ? '' : this.state.client.data.name} onHide={() => this.hideModals()} clickMainButton={() => this.createDebit()}/>
          <ModalMessageDouble type='confirmdebitwarning' show={this.state.showModalWarning} var={this.state.product === null ? '' : global.curSym+this.state.product.data.price.toFixed(2)+lang.t('billing:label.per'+this.state.product.data.billing)} onHide={() => this.hideModals()} clickMainButton={() => this.createDebit()} clickSecondaryButton={() => this.props.history.push(ROUTES.PAYMENT)}/>
          <ModalBilling type='debit' show={this.state.showModalInfo} cycle={this.state.product === null ? '' : this.state.product.data.billing} onHide={() => this.hideModals()}/>
          <ModalMessageSimple type='debitstock' show={this.state.showModalStock} onHide={() => this.hideModals()} clickMainButton={() => this.hideModals()}/>
          <Loader show={this.state.loading}/>
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='debits-locked' type='simple'/>
          </div>
          <Header title={lang.t('billing:title.newmembership')} link={this.state.back}/>
          <Navigation active='billing' />
        </div>
      )
    }
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['billing','common','header','messaging'])(withRouter(withAuthorization(condition)(DebitNewPage)));