import React, { Component } from 'react';
import 'firebase/functions';
import Connector from '../../data/Connector';
import { QRCodeCanvas } from "qrcode.react";
import InputRadio from '../../components/Form/radio';

import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';

import { AuthUserContext, withAuthorization } from '../../components/Session';



class QRPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: global.uid
    };
  }


  componentDidMount() {
    Connector.setUser()
    document.title = 'Signup QR Code - PT Mate'
    window.Intercom("update")
    window.scrollTo(0, 0)
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
  }


  configureData() {
    this.setState({
      uid: global.uid,
      link: 'portal'
    })
  }


  renderAndroid() {
    if(this.state.link === 'android') {
      return (
        <div>
          <QRCodeCanvas
            id="qrCode"
            value={'https://play.google.com/store/apps/details?id=net.ptmate.ptmate_client'}
            size={500}
            bgColor={"#ffffff"}
            level={"H"}
          />
        </div>
      )
    }
  }


  renderIOS() {
    if(this.state.link === 'ios') {
      return (
        <div>
          <QRCodeCanvas
            id="qrCode"
            value={'https://apps.apple.com/us/app/pt-mate-client/id1549621111'}
            size={500}
            bgColor={"#ffffff"}
            level={"H"}
          />
        </div>
      )
    }
  }


  renderPortal() {
    if(this.state.link === 'portal') {
      return (
        <div>
          <QRCodeCanvas
            id="qrCode"
            value={'https://ptmate.me/'+this.state.uid+'/signup'}
            size={500}
            bgColor={"#ffffff"}
            level={"H"}
          />
          <div className="sv-80"></div>
          <a href={'https://ptmate.me/'+this.state.uid+'/signup'} target="_blank" rel="noopener noreferrer">{'https://ptmate.me/'+this.state.uid+'/signup'}</a>
        </div>
      )
    }
  }


  renderBase() {
    return (
      <div className={'content form theme-'+global.spaceTheme} style={{width: 500}}>
        <Link to={'/clients/new'} className="btn tertiary close" style={{float: 'left'}}>Close</Link>
        <div className="clear sv-20"></div>
        <InputRadio
          label=''
          value={this.state.link}
          clickElement={(event) => this.setState({link: event})}
          values={[{name: 'Web Portal', value: 'portal'}, {name: 'iOS App', value: 'ios'}, {name: 'Android App', value: 'android'}]}
        />
        <div className="sv-80"></div>
        {this.renderPortal()}
        {this.renderIOS()}
        {this.renderAndroid()}
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;

export default withRouter(withAuthorization(condition)(QRPage));

