import React, { Component } from 'react';
import Moment from 'moment';
import 'moment/locale/es';
import Firebase from 'firebase';
import Connector from '../../data/Connector';
import HelperCal from '../../helper/Calendar';
import HelperPro from '../../helper/Programming';
import HelperBusiness from '../../helper/Business';
import * as ROUTES from '../../constants/routes';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import ListEmpty from '../../components/List/empty';
import ModalMessageSimple from '../../components/Modals/message-simple';
import ModalVideo from '../../components/Modals/video';
import ModalClients from '../../components/Modals/clients';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class ProgramPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      session: '',
      program: null,
      exercises: global.exercises,
      // Edit elements
      editing: false,
      updating: false,
      values: ['', '', ''],
      blocks: [],
      mins: [],
      secs: [],
      rnds: [],
      // Add exercises
      search: '',
      showOverlayAttach: false,
      hiddenAttach: 'hidden',
      margin: 0,
      currentBlock: 0,
      ex: 'cat',
      clients: global.clients,
      clientsEmail: global.clients,
      selected: [],
      plan: '',
      locked: false,
      // New exercise
      newListEx: [],
      newEx: 'new',
      newExName: '',
      newName: '',
      newTool: 0,
      newVideo: '',
      newUnit: 'reps',
      newError: [false, false, false],
      addList: 99999,
      variations: [],
      placeholders: [],
      custImages: [],
      tools: [],
      toolList: 0,
      benchmark: false,
      all: false,
      unsaved: false,
      replace: 99999,
      tags: '',
      showModalVideo: false,
      showModalDelete: false,
      showModalClients: false,
      showModalEmails: false,
      showModalPayment: false,
    };
  }


  componentDidMount() {
    Connector.setUser()
    document.title = 'Program Details - PT Mate'
    window.Intercom("update")
    window.scrollTo(0, 0)

    global.currentClient = '';

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('programsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('exercisesLoaded', (event) => this.updateExercises())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureClients())
    EventEmitter.subscribe('groupsLoaded', (event) => this.configureClients())
    EventEmitter.subscribe('publicRequestsLoaded', (event) => this.configureData())
    Connector.loadEmailUsage()
    this.configureData()

    var unblock = this.props.history.block(() => {
      if(this.state.unsaved) {
        unblock()
        this.showOverlaySave()
        return false
      } else {
        return true
      }
    });
    if(global.linkPro.indexOf('edit-plan') !== -1) {
      this.setState({
        editing: true
      })
    }
  }


  configureData() {
    if(!this.state.editing) {
      var tmp1 = []
      var tmp2 = []
      var tmp3 = []
      var tmpr = []
      var tblk = []
      var tph = []
      for(var i0=0; i0<60; i0++) {
        tmp1.push(i0+' min')
      }
      for(var i1=0; i1<12; i1++) {
        tmp2.push((i1*5)+' sec')
      }
      for(var i2=0; i2<51; i2++) {
        tmpr.push(i2)
      }

      var values = ['', '', '']

      var id = ''
      var session = ''
      var group = false
      var plan = ''
      var locked = false
      var arr = this.props.location.pathname.split('/')
      id = arr[arr.length-1]
      if(arr.length > 5) {
        if(arr.indexOf('session') !== -1) {
          session = arr[arr.length-3]
        } else if(arr.indexOf('group-session') !== -1) {
          session = arr[arr.length-3]
          group = true
        } else {
          plan = arr[arr.length-3]
          if(arr.indexOf('plan-program') !== -1) {
            locked = true
            plan = arr[arr.length-2]
          }
        }
      }

      var benchmark = false
      var program = null
      var list = global.programs
      if(session !== '') {
        global.backLink = '/calendar/session/'+session
        if(group) {
          global.backLink = '/calendar/group-session/'+session
        }
        for(var sess of global.sessions) {
          if(sess.id === session) {
            list = sess.program
          }
        }
        if(global.linkCli !== '') {
          locked = true
        }
      }
      if(plan !== '') {
        global.backLink = '/programming/plan/'+plan
        for(var pln of global.plans) {
          if(pln.id === plan) {
            list = pln.programs 
          }
        }
        for(var pln2 of global.clientPlans) {
          if(pln2.id === plan) {
            list = pln2.programs
            global.backLink = '/clients/'+arr[arr.length-4]+'/plan/'+pln2.id
          }
        }
      }

      for(var iteme of global.exercises) {
        if(iteme.data.user === global.uidUser) {
          tmp3.push({id: iteme.id, name: iteme.data.name})
        }
      }

      for(var item of list) {
        if(item.id === id) {
          program = item
          var video = ''
          if(item.data.video !== undefined) {
            video = item.data.video
          }
          values = [item.data.name, item.data.desc, video]

          if(item.data.benchmark !== undefined) {
            benchmark = item.data.benchmark
          }

          for(var block of item.blocks) {
            var min = parseInt(block.data.rounds/60)
            var sec = block.data.rounds-(min*60)
            var min2 = 0
            var sec2 = 0
            var logResults = true
            var show = false
            if(block.data.type === 1 || block.data.type === 2) {
              min = parseInt(block.data.exWork[0]/60)
              sec = parseInt(block.data.exWork[0])-(min*60)
              min2 = parseInt(block.data.exRest[0]/60)
              sec2 = parseInt(block.data.exRest[0])-(min2*60)
            }
            if(block.data.type === 4) {
              min = parseInt(block.data.exWork[0]/60)
              sec = parseInt(block.data.exWork[0])-(min*60)
            }
            var ex = []
            for(var i=0; i<block.data.exName.length; i++) {
              var min1 = parseInt(block.data.exWork[i]/60)
              min2 = parseInt(block.data.exRest[i]/60)
              //var show = false
              var weightType = 'per'
              if(block.data.exWeightType !== undefined) {
                weightType = block.data.exWeightType[i]
              }
              if(block.data.notes !== '') {
                show = true
              }
              if(block.data.logResults !== undefined) {
                logResults = block.data.logResults
              }
              var wgt = block.data.exWeight[i]
              if(weightType === 'kg') {
                wgt = block.data.exWeight[i]
              }
              if(block.data.exWeightRounds !== undefined && block.data.exWeightRounds.length > i) {
                wgt = block.data.exWeightRounds[i]
                if(weightType === 'kg') {
                  wgt = block.data.exWeightRounds[i]
                }
              }
              var rps = block.data.exReps[i]
              if(block.data.exRepsRounds !== undefined && block.data.exRepsRounds.length > i) {
                rps = block.data.exRepsRounds[i]
              }
              var unit = ''
              if(block.data.exUnits !== undefined && block.data.exUnits.length > i) {
                unit = block.data.exUnits[i]
              }
              var notes = ''
              if(block.data.exNotes !== undefined && block.data.exNotes.length > i) {
                notes = block.data.exNotes[i]
              }
              ex.push({
                id: block.data.exId[i],
                name: block.data.exName[i],
                tool: block.data.exTool[i],
                image: block.data.exImage[i],
                cat: block.data.exCat[i],
                type: block.data.exType[i],
                reps: rps,
                weight: wgt,
                wmin: min1,
                wsec: block.data.exWork[i]-(min1*60),
                rmin: min2,
                rsec: block.data.exRest[i]-(min2*60),
                weighttype: weightType,
                unit: unit,
                notes: notes
              })
            }
            var rounds = 1
            if(block.data.type !== 5) {
              rounds = block.data.rounds
            } else {
              if(block.data.cycles !== undefined) {
                rounds = block.data.cycles
              }
            }
            //var show = false
            if(block.data.type === 1 || block.data.type === 4 || block.data.type === 5) {
              if(rounds > 1) {
                show = true
              }
            }
            var name = ''
            if(block.data.name !== undefined) {
              name = block.data.name
            }
            var preset = ''
            if(block.data.preset !== undefined) {
              preset = block.data.preset
            }
            tblk.push({
              type: block.data.type,
              cat: block.data.cat,
              rounds: rounds,
              emom: block.data.emom,
              notes: block.data.notes,
              exercises: ex,
              min: min,
              sec: sec,
              min2: min2,
              sec2: sec2,
              show: show,
              logResults: logResults,
              showInfo: show,
              name: name,
              preset: preset,
            })
            tph.push(lang.t('program:label.typemovementname'))
          }
        }
      }
      this.configureClients()

      this.setState({
        id: id,
        session: session,
        plan: plan,
        mins: tmp1,
        secs: tmp2,
        rnds: tmpr,
        exercises: global.exercises,
        program: program,
        blocks: tblk,
        values: values,
        locked: locked,
        placeholders: tph,
        newListEx: tmp3,
        benchmark: benchmark,
      })
    }
  }


  updateExercises() {
    this.setState({
      exercises: global.exercises
    })
  }


  configureClients() {
    var tmp = []
    var tmp2 = []
    for(var item of global.clients) {
      if(item.data.uid !== '') {
        tmp.push(item)
      }
      if(item.data.email !== '') {
        tmp2.push(item)
      }
    }
    this.setState({
      clients: tmp,
      clientsEmail: tmp2,
    })
  }


  saveToPrograms() {
    global.programLocked = true
    Firebase.database().ref('/workouts/'+global.uid+'/'+this.state.program.id).update(
      this.state.program.data
    ).then((data)=>{
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.programsaved'));
      global.programLocked = false
      Connector.loadPrograms((result) => {})
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  getImg(image) {
    var label = ''
    for(var item of this.state.custImages) {
      if(item.image === image) {
        label = item.url
      }
    }
    if(label === '') {
      Firebase.storage().ref().child(image).getDownloadURL().then((url) => {
        var tmp = this.state.custImages
        tmp.push({
          image: image,
          url: url
        })
        this.setState({
          custImages: tmp
        })
      }).catch((error) => {})
    }
    return label
  }



  // Session overlay ------------------------------------------------------------



  showOverlayAttach() {
    this.setState({
      showOverlayAttach: true,
      selected: [],
    })
    setTimeout(() => {
      this.setState({
        hiddenAttach: '',
      });
    }, 100);
  }


  hideOverlayAttach() {
    this.setState({
      hiddenAttach: 'hidden',
    })
    setTimeout(() => {
      this.setState({
        showOverlayAttach: false,
      });
    }, 500);
  }


  getSessionName(item) {
    var label = lang.t('modals:items.groupsession')
    if(!item.data.group) {
      for(var cl of global.clients) {
        if(cl.id === item.data.client) {
          label = cl.data.name
        }
      }
    } else {
      if(item.data.client !== '') {
        label = item.data.client
      }
      if(item.data.availability) {
        label = lang.t('modals:attach.available')
      }
    }
    return label
  }


  getSessionIcon(item) {
    if(item.data.group) {
      if(item.data.availability) {
        return (
          <div className="icon secondary">
            <div className="inner session"></div>
          </div>
        )
      } else {
        return (
          <div className="icon primary">
            <div className="inner group"></div>
          </div>
        )
      }
    } else {
      return (
        <div className="icon secondary">
          <div className="inner session"></div>
        </div>
      )
    }
  }


  getSelected(item) {
    var label = 'list bare'
    for(var sel of this.state.selected) {
      if(sel === item) {
        label = 'list bare selected'
      }
    }
    return label
  }


  renderOverlayAttach() {
    if(this.state.showOverlayAttach) {
      var list = []
      for(var item of global.sessions) {
        if(Moment(item.data.timestamp, 'X') > Moment() && Moment(item.data.timestamp, 'X') < Moment().add(31, 'days')) {
          list.push(item)
        }
      }
      list.sort((a,b) => Moment(a.data.date, 'DD/MM/YYYY HH:mm') - Moment(b.data.date, 'DD/MM/YYYY HH:mm'))
      return (
        <div className={'overlay '+this.state.hiddenAttach}>
          <div className="box clients">
            <h2 className="mb-20 lft">{lang.t('modals:attach.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayAttach()}>{lang.t('common:button.close')}</button>
            <div className="sv-20"></div>
            <div className="scroll">
              {list.map(item => (
                <div className={this.getSelected(item.id)} onClick={() => this.selectSession(item.id)} key={item.id}>
                  {this.getSessionIcon(item)}
                  <div className="main">
                    <h4>{this.getSessionName(item)}</h4>
                    <p>{Moment(item.data.timestamp, 'X').locale(lang.language).format('D MMM LT')}</p>
                  </div>
                  <div className="clear"></div>
                </div>
              ))}
            </div>
            <button className="btn primary" onClick={() => this.attachSession()}>{lang.t('modals:attach.button')}</button>
          </div>
        </div>
      )
    }
  }


  selectSession(item) {
    var tmp = this.state.selected
    if(tmp.indexOf(item) !== -1) {
      for(var i=0; i<tmp.length; i++) {
        if(tmp[i] === item) {
          tmp.splice(i, 1)
        }
      }
    } else {
      tmp.push(item)
    }
    this.setState({
      selected: tmp
    })
  }


  attachSession() {
    for(var item1 of this.state.selected) {
      Firebase.database().ref('/sessions/'+global.uid+'/'+item1+'/workout/').remove()
    }
    setTimeout(() => {
      for(var item of this.state.selected) {
        Firebase.database().ref('/sessions/'+global.uid+'/'+item+'/workout/'+this.state.program.id).update(
          this.state.program.data
        ).then((data)=>{
          //
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
        for(var block of this.state.program.blocks) {
          Firebase.database().ref('/sessions/'+global.uid+'/'+item+'/workout/'+this.state.program.id+'/blocks/'+block.id).push(
            block.data
          )
        }
      }
    }, 100);
    this.hideOverlayAttach()
    if(this.state.selected.length > 0) {
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.programattached'));
    }
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalVideo: false,
      showModalDelete: false,
      showModalClients: false,
      showModalEmails: false,
      showModalPayment: false,
    })
  }


  showEmails() {
    if(global.userCard === '' || global.userCard === undefined) {
      this.setState({
        showModalPayment: true
      })
    } else {
      this.setState({
        showModalEmails: true,
        selected: [],
      })
    }
  }


  sendProgram(event) {
    if(event.length > 0) {
      var tokens = []
      for(var item of event) {
        for(var cl of this.state.clients) {
          if(cl.id === item && cl.data.uid !== undefined && cl.data.uid !== '') {
            Firebase.database().ref('/workouts/'+cl.data.uid+'/'+this.state.program.id).update(
              this.state.program.data
            ).then((data)=>{
              //
            }).catch((error)=>{
              EventEmitter.dispatch('showMessageError', error.message);
            })
            for(var block of this.state.program.blocks) {
              Firebase.database().ref('/workouts/'+cl.data.uid+'/'+this.state.program.id+'/blocks/'+block.id).push(
                block.data
              )
            }
            if(cl.data.pushToken !== undefined && cl.data.pushToken !== '') {
              tokens.push(cl.data.pushToken)
            }
          }
        }
      }
      if(tokens.length > 0) {
        Connector.sendPushNotification('', lang.t('messaging:push.program.title'), global.userName+lang.t('messaging:push.program.text'), 'program', this.state.program.id, tokens)
      }
      this.hideModals()
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.programsent'));
    }
  }


  duplicateProgram() {
    var tmp = this.state.program.data
    tmp.name = tmp.name+" (Copy)"
    var obj = Firebase.database().ref('/workouts/'+global.uid+'/').push()
    obj.update(
      tmp
    ).then((data)=>{
      //
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
    for(var block of this.state.program.blocks) {
      Firebase.database().ref('/workouts/'+global.uid+'/'+obj.key+'/blocks/'+block.id).push(
        block.data
      )
    }
    global.message = lang.t('messaging:message.programduplicated')
    setTimeout(() => {
      this.props.history.push('/programming/')
    }, 500);
  }


  deleteProgram() {
    this.hideModals()
    if(this.state.session === '') {
      Firebase.database().ref('/workouts/'+global.uid+'/'+this.state.id).remove()
      .then((data)=>{
        global.message = lang.t('messaging:message.programdeleted')
        this.props.history.push(ROUTES.PROGRAMMING)
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    } else {
      Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.session+'/workout/'+this.state.id).remove()
      .then((data)=>{
        global.message = lang.t('messaging:message.programdeleted')
        this.props.history.push(global.linkCal)
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
  }


  sendProgramEmail(event) {
    this.hideModals()
    var emails = []
    for(var item of event) {
      for(var cl of this.state.clients) {
        if(cl.id === item && cl.data.email !== undefined && cl.data.email !== '') {
          emails.push(cl.data.email)
        }
      }
    }
    if(emails.length > 0) {
      var base = 0
      if(global.spaceEmailUsage[Moment().format('MM-YYYY')] !== undefined) {
        base = global.spaceEmailUsage[Moment().format('MM-YYYY')]
      }
      Firebase.database().ref('/admin/emails/'+global.uid).update({
        [Moment().format('MM-YYYY')]: base+emails.length
      })
      var content = HelperPro.createEmail(this.state.program)
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.emailssent'));
      var callFunction = Firebase.functions().httpsCallable('sendMarketingV2');
      callFunction({sender: global.userName, email: emails, subject: lang.t('messaging:label.newprogramfrom')+global.userBusiness, content: content}).then(function(result) {});
    }
  }



  // Display stuff ------------------------------------------------------------



  renderSetsNameStatic(item) {
    var s = ''
    if(item.rounds > 1) {
       s = 's'
    }
    var label = lang.t('program:label.set'+s)
    if(item.exId.length > 1) {
      var prev = ''
      var multiple = true
      for(var ex of item.exId) {
        if(ex !== prev && prev !== '') {
          label = lang.t('program:label.superset'+s)
          multiple = false
        }
        if(ex === prev && prev !== '') {
          label = lang.t('program:label.dropset'+s)
        }
        prev = ex
      }
      if(multiple) {
        label = lang.t('program:label.dropset'+s)
      } else {
        label = lang.t('program:label.superset'+s)
      }
    }
    return label
  }


  renderBlockTypeStatic(item) {
    var label = ''
    var rnd = lang.t('program:label.round')
    if(item.rounds > 1) {
      rnd = lang.t('program:label.rounds')
    }
    if(item.type === 0) {
      label = ' - '+HelperCal.getDurationMin(item.rounds)
    }
    if(item.type === 1) {
      label = ' - '+item.rounds+' '+rnd
      if(item.emom) {
        label = ' - '+item.rounds+' '+rnd+' '+lang.t('program:label.of')+' '+HelperCal.getDurationMin(parseInt(item.exWork[0]))
      }
    }
    if(item.type === 2 || item.type === 3) {
      label = ' - '+item.rounds+' '+lang.t('program:label.rounds')
      if(item.rounds === 1) {
        label = ' - 1 '+lang.t('program:label.round')
      }
      if(item.type === 2 && item.emom) { 
        if(item.exRest[0] === 0) {
          label += ' '+lang.t('program:label.of')+' '+HelperCal.getDurationMin(parseInt(item.exWork[0]))+' '+lang.t('program:label.worknorest')
        } else {
          label += ' '+lang.t('program:label.of')+' '+HelperCal.getDurationMin(parseInt(item.exWork[0]))+' '+lang.t('program:label.workand')+' '+HelperCal.getDurationMin(parseInt(item.exRest[0]))+' '+lang.t('program:label.rest-s')
        }
      }
    }
    if(item.type === 4) {
      label = ' - '+item.rounds+' '+this.renderSetsNameStatic(item)
      if(item.rounds === 1) {
        label = ' - 1 '+this.renderSetsNameStatic(item)
      }
    }
    if(item.type === 5) {
      label = ' - '+HelperCal.getDurationMin(item.rounds)
      if(item.cycles > 1) {
        label = ' - '+item.cycles+' '+lang.t('program:label.rounds')+' - '+lang.t('program:label.cap')+': '+HelperCal.getDurationMin(item.rounds)
      }
    }
    return label
  }


  renderRepsStatic(item, index) {
    var label = item.exReps[index]
    if(item.exRepsRounds !== undefined && item.exRepsRounds !== '') {
      if(item.exRepsRounds.length > index) {
        if(item.exRepsRounds[index] !== '') {
          label = item.exRepsRounds[index].replaceAll('-', ' - ')
        }
      }
    }
    if(item.exUnits !== undefined) {
      if(item.exUnits.length > index) {
        if(item.exUnits[index] === 'dist') {
          label += 'm'
        }
        if(item.exUnits[index] === 'cals') {
          label += 'cal'
        }
        if(item.exUnits[index] === 'time') {
          label += 's'
        }
      }
    } else {
      if(item.exTool[index] === 6 || item.exTool[index] === 7) {
        label += 'm'
      }
      if(item.exTool[index] === 27) {
        label += 'cal'
      }
      if(item.exTool[index] === 28) {
        label += 's'
      }
    }
    if(item.exReps[index] === 0) {
      label = ''
    }
    return label
  }


  renderWeightStatic(item, index) {
    if(item.exWeight[index] !== 0) {
      var label = lang.t('program:label.at')+' '+item.exWeight[index]+'% '+lang.t('program:label.of1rm')
      if(item.exWeightRounds !== undefined && item.exWeightRounds !== '') {
        label = ''+lang.t('program:label.at')+' '+item.exWeightRounds[index].replaceAll('-', ' - ')+'% '+lang.t('program:label.of1rm')
      }
      if(item.exWeightType !== undefined) {
        if(item.exWeightType.length > index && (item.exWeightType[index] === 'kg' || item.exWeightType[index] === 'lb')) {
          label = lang.t('program:label.with')+' '+item.exWeight[index]+' '+item.exWeightType[index]
          if(item.exWeightRounds !== undefined && item.exWeightRounds !== '') {
            label = lang.t('program:label.with')+' '+item.exWeightRounds[index].replaceAll('-', ' - ')+' '+item.exWeightType[index]
          }
        }
      }
      return label
    }
  }


  renderTimeStatic(block, index) {
    if((block.type === 1 || block.type === 2) && !block.emom) {
      return (
        <div className="col-2 program-time">
          <p>{HelperCal.getDurationMin(parseInt(block.exWork[index]))}</p>
        </div>
      )
    }
  }


  renderNotesStatic(item, index) {
    if(item.exNotes !== undefined) {
      if(item.exNotes.length > index) {
        if(item.exNotes[index] !== '') {
          return (
            <span className="program-notes movement"><br/>{item.exNotes[index]}</span>
          )
        }
      }
    }
  }


  renderExerciseRest(block, index) {
    if(block.type === 2 && !block.emom && block.exRest[index] !== 0 && block.exRest[index] !== undefined && block.exRest[index] !== '') {
      return (
        <div className="box list simple highlight mb-10">
          <div className="col-10">
            <div className="icon secondary lft mr-10">
              <div className="inner prog-rest"></div>
            </div>
            <p className="lft"><strong>{lang.t('program:label.rest')}</strong></p>
          </div>
          <div className="col-2 program-time">
            <p>{HelperCal.getDurationMin(parseInt(block.exRest[index]))}</p>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderExercisesStatic(num) {
    var block = this.state.program.blocks[num].data
    if(!block.simple) {
      return (
        <div>
          {block.exName.map((item, index) => (
            <div key={index}>
              <div className="box list simple highlight mb-10">
                <div className="col-10">
                  <div className="icon primary lft mr-10">
                    <div className={'inner '+global.exCatsIcons[block.cat]}></div>
                  </div>
                  <p className="lft"><strong>{this.renderRepsStatic(block, index)} {item}</strong> {this.renderWeightStatic(block, index)}{this.renderNotesStatic(block, index)}</p>
                </div>
                {this.renderTimeStatic(block, index)}
                <div className="clear"></div>
              </div>
              {this.renderExerciseRest(block, index)}
            </div>
          ))}
        </div>
      )
    }
  }


  renderStaticRest(block) {
    if(block.type === 4 && block.exWork[0] !== 0 && block.exWork[0] !== undefined) {
      return (
        <div className="box list simple highlight mb-10">
          <div className="col-10">
          <div className="icon secondary lft mr-10">
              <div className="inner prog-rest"></div>
            </div>
            <p className="lft"><strong>{lang.t('program:label.rest')}</strong></p>
          </div>
          <div className="col-2 program-time">
            <p>{HelperCal.getDurationMin(parseInt(block.exWork[0]))}</p>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderStaticNotes(item) {
    if(item.data.notes !== '') {
      if(item.data.simple !== undefined) {
        if(item.data.simple) {
          return <p className="mb-20 pl-10" style={{whiteSpace: 'pre-wrap'}}>{item.data.notes}</p>
        } else {
          return <p className="small mb-20 pl-10" style={{whiteSpace: 'pre-wrap'}}>{item.data.notes}</p>
        }
      } else {
        return <p className="small mb-20 pl-10" style={{whiteSpace: 'pre-wrap'}}>{item.data.notes}</p>
      }
    }
  }


  renderBlockResultsStatic(item) {
    if(item.data.logResults !== undefined && !item.data.logResults) {
      return <span className="pill primary rgt">{lang.t('program:label.resultswontbelogged')}</span>
    }
  }


  renderBlockTitleStatic(item) {
    var adding = ""
    if(item.data.simple && item.data.unitSimple !== undefined && item.data.unitSimple !== '') {
      adding = ' ('+lang.t('program:form.for')+" "+lang.t('program:form.result'+item.data.unitSimple)+')'
    }
    if(item.data.name !== '' && item.data.name !== undefined) {
      return item.data.name+adding
    } else {
      return lang.t(global.exCats[item.data.cat])+adding
    }
  }


  renderBlocksStatic() {
    return (
      <div>
        {this.state.program.blocks.map((item, index) => (
          <div key={index}>
            <div className="clear mb-50">
              <label>{this.renderBlockTitleStatic(item)}</label>
              <h3 className="clear lft mb-20">{lang.t(global.proTypes[item.data.type])} {this.renderBlockTypeStatic(item.data)}</h3>
              {this.renderBlockResultsStatic(item)}
              <div className="clear"></div>
              {this.renderExercisesStatic(index)}
              {this.renderStaticRest(item.data)}
              {this.renderStaticNotes(item)}
              <p className="small program-notes pl-10">{lang.t('program:label.thisblockwilltakeabout')} {HelperCal.getDurationMin(HelperCal.calcBlockTime(this.state.blocks[index].exercises, item.data.type, item.data.rounds, item.data.emom, item.data.exWork[0], item.data.exRest[0]))}</p>
            </div>
          </div>
        ))}
      </div>
    )
  }


  renderStaticBtnArray() {
    if(HelperBusiness.getRoleIncl('trainer')) {
      if(this.state.plan === '' && this.state.session === '') {
        return (
          <div>
            <div className="col-4 mb-20">
              <Link className="btn primary" to={'/programming/edit-program/'+this.state.id}>{lang.t('program:button.editprogram')}</Link>
            </div>
            <div className="col-4 mb-20">
              <a className="btn secondary" href={'/programming/print-program/'+this.state.id} target="_blank" rel="noreferrer noopener">{lang.t('program:button.print')}</a>
            </div>
            <div className="col-4 mb-20">
              <button className="btn secondary" onClick={() => this.showOverlayAttach()}>{lang.t('program:button.attachtoasession')}</button>
            </div>
            <div className="col-4 clear mb-20">
              <button className="btn secondary" onClick={() => this.setState({showModalClients: true, selected: []})}>{lang.t('program:button.sendtomemberapp')}</button>
            </div>
            <div className="col-4 mb-20">
              <button className="btn secondary" onClick={() => this.showEmails()}>{lang.t('program:button.sendviaemail')}</button>
            </div>
            <div className="col-4">
              <button className="btn secondary" onClick={() => this.duplicateProgram()}>{lang.t('program:button.duplicatethisprogram')}</button>
            </div>
            <div className="clear sv-30"></div>
          </div>
        )
      }
    }
  }


  renderStaticButtons() {
    var show = true
    if(this.state.session !== '') {
      for(var item of global.sessions) {
        if(item.id === this.state.session) {
          var sdate = parseInt(Moment(item.data.timestamp, 'X').add(-5, 'minutes').format('X'))
          var now = parseInt(Moment().format('X'))
          if(now > sdate) {
            show = false
          }
        }
      }
      
    }
    
    if(!this.state.locked && show && HelperBusiness.getRoleIncl('trainer')) {
      if((this.state.benchmark && this.state.session === '' && this.state.plan === '') || !this.state.benchmark) {
        return (
          <div>
            {this.renderStaticBtnArray()}
            <button className="btn tertiary width-12" onClick={() => this.setState({showModalDelete: true})}>{lang.t('program:button.deleteprogram')}</button>
            <div className="sv-40"></div>
          </div>
        )
      } else {
        return (
          <div>
            <div className="width-6">
              <button className="btn tertiary width-12" onClick={() => this.setState({showModalDelete: true})}>{lang.t('program:button.deleteprogram')}</button>
            </div>
            <div className="sv-20"></div>
            {this.renderSend()}
          </div>
        )
      }
      
    }
  }


  renderBenchmark() {
    if(this.state.benchmark) {
      return (
        <div className="infobox large green mb-20">
          <p>{lang.t('program:label.thisisabenchmarkprogram')}</p>
        </div>
      )
    }
  }


  renderTitle() {
    if(this.state.plan !== '') {
      return lang.t('program:title.trainingplanprogram')
    } else if(this.state.session !== '') {
      return lang.t('program:title.sessionprogram')
    } else {
      if(this.state.benchmark) {
        return lang.t('program:title.benchmarkprogram')
      } else {
        return lang.t('program:title.program')
      }
      
    }
  }


  renderBack() {
    var link = ROUTES.PROGRAMMING
    if(global.linkCal !== '') {
      link = global.linkCal
    }
    if(global.linkPro !== '') {
      link = global.linkPro
    }
    if(global.linkCli !== '') {
      link = global.linkCli
    }
    return link
  }


  renderButtonVideo() {
    if(this.state.program.data.video !== undefined && this.state.program.data.video !== "") {
      return <button className="btn tertiary" onClick={() => this.setState({showModalVideo: true})}>{lang.t('program:button.watchvideo')}</button>
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('assistant,assistant2,trainer')) {
      if(this.state.program === null) {
        return (
          <div></div>
        )
      } else {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content form-sidebar mt-20">
              {this.renderBenchmark()}
              <div className="program-header mb-60">
                <div className={'icon large lft mr-20 '+HelperCal.getColor(this.state.program.data.time)}>
                  <p>{this.state.program.data.time}'</p>
                </div>
                <div className="main">
                  <h2>{this.state.program.data.name}</h2>
                  <p className="mb-10">{this.state.program.data.desc}</p>
                  {this.renderButtonVideo()}
                </div>
                <div className="clear"></div>
              </div>
              <div className="col-12">
                {this.renderBlocksStatic()}
              </div>
              {this.renderStaticButtons()}
            </div>
            <Header title={this.renderTitle()} link={this.renderBack()}/>
            <Navigation active='programming' />
            {this.renderOverlayAttach()}
            <ModalVideo show={this.state.showModalVideo} url={this.state.program === null ? '' : this.state.program.data.video} onHide={() => this.hideModals()}/>
            <ModalMessageSimple type='deleteprogram2' show={this.state.showModalDelete} onHide={() => this.hideModals()} clickMainButton={() => this.deleteProgram()}/>
            <ModalClients title={lang.t('modals:clients.title.sendtomyclients')} clients={this.state.clients} multiple={true} show={this.state.showModalClients} selected={this.state.selected} onHide={() => this.hideModals()} clickMainButton={(event) => this.sendProgram(event)}/>
            <ModalClients title={lang.t('modals:clients.title.sendviaemail')} clients={this.state.clientsEmail} multiple={true} message={true} show={this.state.showModalEmails} selected={this.state.selected} onHide={() => this.hideModals()} clickMainButton={(event) => this.sendProgramEmail(event)}/>
            <ModalMessageSimple type='emailprogramslocked' show={this.state.showModalPayment} onHide={() => this.hideModals()} clickMainButton={() => this.props.history.push(ROUTES.ACCOUNT)}/>
          </div>
        )
      }
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='programming-locked' type='simple'/>
          </div>
          <Header title={lang.t('program:title.program')} link={this.renderBack()}/>
          <Navigation active='programming' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['program','common','header','modals','messaging'])(withRouter(withAuthorization(condition)(ProgramPage)));