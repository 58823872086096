import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import Connector from '../../data/Connector';
import Cal from '../../helper/Calendar';
import HelperBusiness from '../../helper/Business';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form-tabs';
import ListEmpty from '../../components/List/empty';
import InputText from '../../components/Form/input-text';
import InputNumber from '../../components/Form/input-number';
import InputDatetime from '../../components/Form/datetime';
import InputToggle from '../../components/Form/toggle';
import Select from '../../components/Form/select';
import InputDuration from '../../components/Form/duration';
import InputInvited from '../../components/Form/invited';
import WidgetFormInfo from '../../components/Widgets/forminfo';
import ModalClients from '../../components/Modals/clients';
import ModalItems from '../../components/Modals/items';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class NewGroupPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      item: null,
      dur: 30,
      preview: true,
      name: '',
      notes: '',
      sname: '',
      desc: '',
      date: Moment(global.currentDate, 'DD/MM/YYYY').format('DD/MM/YYYY'),
      hour: global.currentTime,
      mins: '00',
      cdur: 75,
      showMax: false,
      max: 0,
      error: [false, false, false],
      back: ROUTES.CALENDAR,
      private: '',
      invited: [],
      group: [],
      memberships: [],
      trainerId: global.uidUser,
      trainerName: global.userName,
      location: global.loc,
      link: '',
      showBook: false,
      dateBook: Moment().format('DD/MM/YYYY'),
      hourBook: '00',
      minsBook: '00',
      showBookCancel: false,
      dateBookCancel: Moment().add(14, 'days').format('DD/MM/YYYY'),
      hourBookCancel: '00',
      minsBookCancel: '00',
      showMore: false,
      avail: false,
      staff: global.userStaff,
      trainers: [],
      locations: [],
      showModalClients: false,
      showModalGroups: false,
      showModalMemberships: false,
      editing: false,
      timeEdit: false,
      products: [],
    };
  }


  componentDidMount() {
    Connector.setUser()
    this._isMounted = true
    window.scrollTo(0, 0)

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('groupsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('productsLoaded', (event) => this.configureData())

    var back = global.currentPage
    if(global.linkCli !== '') {
      back = global.linkCli
    }

    var avail = false
    if(this.props.location.pathname.indexOf('availability') !== -1) {
      avail = true 
      document.title = '1:1 Availability - PT Mate'
      window.Intercom("update")
    } else {
      document.title = 'Class - PT Mate'
      window.Intercom("update")
    }

    this.setState({
      back: back,
      avail: avail
    })
    this.configureData()

    if(global.clientTokens.length === 0) {
      for(var client of global.clients) {
        if(client.data.uid !== '') {
          Connector.getClientTokenAll(client.data.uid, client.id)
        }
      }
    }
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      var tmp1 = []
      var tmp2 = []
      var tmp22 = []
      var tmp23 = []
      var tmpp = []
      for(var item1 of global.clients) {
        tmp1.push(item1)
      }
      for(var item2 of global.userStaff) {
        if((item2.data.role === 'assistant' || item2.data.role === 'assistant2' || item2.data.role === 'trainer') && item2.data.status === 'active') {
          tmp2.push(item2)
        }
      }
      if(tmp2.length > 0) {
        tmp22 = [{value: '', name: '- '+lang.t('calendar:form.notassigned')+' -'}, {value: global.uidUser, name: lang.t('common:label.yourself')}]
        if(global.userRole === 'manager') {
          tmp22.push({value: global.uid, name: global.spaceOwner})
        }
        for(var t of tmp2) {
          tmp22.push({value: t.id, name: t.data.name})
        }
        
      }
      if(global.locations.length > 0) {
        tmp23 = [{value: '', name: '- '+lang.t('calendar:form.notassigned')+' -'}]
        for(var l of global.locations) {
          tmp23.push({value: l.id, name: l.data.name})
        }
      }
      for(var item3 of global.products) {
        if(item3.data.type !== 'other') {
          tmpp.push(item3)
        }
      }

      this.setState({
        clients: tmp1,
        clientsList: tmp1,
        groups: global.clientGroups,
        staff: tmp2,
        trainers: tmp22,
        locations: tmp23,
        products: tmpp,
      })
      if(this.props.location.pathname.indexOf('/edit-group-session/') !== -1) {
        this.configureEditing()
      }
    }
  }


  configureEditing() {
    var arr = this.props.location.pathname.split('/')
    var back = ROUTES.CALENDAR

    var id = arr[arr.length-1]
    var item = null
    var cl = null
    var dur = 30
    var notes = ''
    var sname = ''
    var desc = ''
    var day = ''
    var hrs = ''
    var min = ''
    var preview = true
    var title = lang.t('calendar:session.group')
    var max = 0
    var show = false
    var name = ''
    var priv = ''
    var invited = []
    var tid = global.uidUser
    var tname = global.userName
    var link = ''
    var dayBook = '01/01/1900'
    var hrsBook = '00'
    var minBook = '00'
    var showBook = false
    var dayBookCancel = '01/01/2100'
    var hrsBookCancel = '00'
    var minBookCancel = '00'
    var showBookCancel = false
    var group = []
    var groups = []
    var memberships = []
    var membershipsf = []
    var location = ''

    if(id !== '') {
      back = '/calendar/group-session/'+id
      // Sessions
      for(var session of global.sessions) {
        if(session.id === id) {
          item = session
          dur = session.data.duration
          notes = session.data.notes
          day = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
          hrs = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('HH')
          min = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('mm')
          name = session.data.client
          var avail = false
          if(session.data.client !== '') {
            title = session.data.client
          }
          if(!session.data.preview && session.data.preview !== undefined) {
            preview = false
          }
          if(session.data.max !== undefined && session.data.max > 0) {
            max = session.data.max
            show = true
          }
          if(session.data.invitees !== undefined && session.data.invitees.length > 0) {
            priv = 'private'
            invited = session.data.invitees
          }
          if(session.data.groups !== undefined && session.data.groups.length > 0) {
            priv = 'group'
            group = session.data.groups
          }
          if(session.data.memberships !== undefined && session.data.memberships.length > 0) {
            priv = 'group'
            memberships = session.data.memberships
          }
          if(session.data.trainerId !== undefined) {
            tid = session.data.trainerId
            tname = session.data.trainerName
          }
          if(session.data.link !== undefined) {
            link = session.data.link
          }
          if(session.data.availability !== undefined) {
            avail = session.data.availability
          }
          if(session.data.name !== undefined) {
            sname = session.data.name
          }
          if(session.data.desc !== undefined) {
            desc = session.data.desc
          }
          if(session.data.location !== undefined) {
            location = session.data.location
          }
          if(session.data.unlocked !== undefined) {
            dayBook = Moment(session.data.unlocked, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
            hrsBook = Moment(session.data.unlocked, 'DD/MM/YYYY HH:mm').format('HH')
            minBook = Moment(session.data.unlocked, 'DD/MM/YYYY HH:mm').format('mm')
            if(session.data.unlocked !== '01/01/1900 00:00' && Moment(session.data.unlocked, 'DD/MM/YYYY HH:mm') > Moment('01/01/2021', 'DD/MM/YYYY')) {
              showBook = true
            } else {
              dayBook = Moment().format('DD/MM/YYYY')
            }
          }
          if(session.data.locked !== undefined) {
            dayBookCancel = Moment(session.data.locked, 'X').format('DD/MM/YYYY')
            hrsBookCancel = Moment(session.data.locked, 'X').format('HH')
            minBookCancel = Moment(session.data.locked, 'X').format('mm')
            if(Moment(session.data.locked, 'X') < Moment(session.data.timestamp, 'X')) {
              showBookCancel = true
            } else {
              dayBookCancel = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
              hrsBookCancel = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('HH')
              minBookCancel = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('mm')
            }
          }
          if(group.length > 0) {
            for(var gr of global.clientGroups) {
              if(group.indexOf(gr.id) !== -1) {
                groups.push(gr)
              }
            }
          }
          if(memberships.length > 0) {
            for(var pr of global.products) {
              if(memberships.indexOf(pr.id) !== -1) {
                membershipsf.push(pr)
              }
            }
          }
        }
      }
    }

    var cdur = 75
    if(dur !== 30 && dur !== 40 && dur !== 45 && dur !== 60 && dur !== 90) {
      cdur = dur
      dur = 99
    }

    var tmps = []
    if(item !== null) {
      if(item.data.availability) {
        title = lang.t('calendar:session.available')
      }

      for(var items of global.userStaff) {
        if((items.data.role === 'assistant' || items.data.role === 'assistant2' || items.data.role === 'trainer') && items.data.status === 'active') {
          tmps.push(items)
        }
      }
    }

    global.linkCal = '/calendar/group-session/'+id
    this.setState({
      client: cl,
      groups: global.clientGroups,
      item: item,
      id: id,
      back: back,
      dur: dur,
      cdur: cdur,
      notes: notes,
      sname: sname,
      desc: desc,
      date: Moment(day, 'DD/MM/YYYY').format('DD/MM/YYYY'),
      hour: hrs,
      mins: min,
      preview: preview,
      title: title,
      name: name,
      max: max,
      group: groups,
      memberships: membershipsf,
      showMax: show,
      private: priv,
      invited: invited,
      trainerId: tid,
      trainerName: tname,
      location: location,
      link: link,
      showBook: showBook,
      dateBook: Moment(dayBook, 'DD/MM/YYYY').format('DD/MM/YYYY'),
      hourBook: hrsBook,
      minsBook: minBook,
      showBookCancel: showBookCancel,
      dateBookCancel: Moment(dayBookCancel, 'DD/MM/YYYY').format('DD/MM/YYYY'),
      hourBookCancel: hrsBookCancel,
      minsBookCancel: minBookCancel,
      showMore: false,
      avail: avail,
      staff: tmps,
      editing: true,
    })
  }



  // Client update stuff ------------------------------------------------------------



  setTrainer(item) {
    var name = ''
    for(var trainer of global.userStaff) {
      if(trainer.id === item) {
        name = trainer.data.name
      }
    }
    if(item === global.uidUser) {
      name = global.userName
    }
    this.setState({
      trainerId: item,
      trainerName: name
    })
  }


  checkCreateSession() {
    var passed = true
    var tmp = [false, false, false]
    var date = this.state.date+' '+this.state.hour+':'+this.state.mins
    var later = true
    /*if(parseInt(Moment(date, 'DD/MM/YYYY HH:mm').format('X')) < parseInt(Moment().format('X'))) {
      later = false
    }*/
    if(this.state.invited.length === 0 && this.state.private === 'private') {
      passed = false
      tmp[1] = true
    }
    if(this.state.group.length === 0 && this.state.memberships.length === 0 && this.state.private === 'group') {
      passed = false
      tmp[1] = true
    }
    this.setState({error: [false, false, false]})
    if(!later) {
      tmp[0] = true
      passed = false
    }
    this.setState({error: tmp})
    if(passed) {
      this.createSession()
    }
  }


  createSession() {
    global.badgeLocked = true
    var date = this.state.date+' '+this.state.hour+':'+this.state.mins
    var duration = parseInt(this.state.dur)
    if(this.state.dur === 99) {
      duration = parseInt(this.state.cdur)
    }
    var max = 0
    if(this.state.showMax) {
      max = parseInt(this.state.max)
    }
    var location = null
    var locationName = null
    if(global.locations.length > 0) {
      location = this.state.location
      locationName = ""
      for(var loc of global.locations) {
        if(loc.id === this.state.location) {
          locationName = loc.data.name
        }
      }
    }
    var invitees = []
    var groups = []
    var memberships = []
    if(this.state.invited.length > 0 && this.state.private === 'private') {
      invitees = this.state.invited
    }
    if(this.state.group.length > 0 && this.state.private === 'group') {
      for(var gr of this.state.group) {
        groups.push(gr.id)
      }
    }
    if(this.state.memberships.length > 0 && this.state.private === 'group') {
      for(var pr of this.state.memberships) {
        memberships.push(pr.id)
      }
    }
    var unlocked = '01/01/1900 00:00'
    if(this.state.showBook) {
      unlocked = this.state.dateBook+' '+this.state.hourBook+':'+this.state.minsBook
    }
    var lock = '01/01/2100 00:00'
    if(this.state.showBookCancel) {
      lock = this.state.dateBookCancel+' '+this.state.hourBookCancel+':'+this.state.minsBookCancel
    }
    var locked = parseInt(Moment(lock, 'DD/MM/YYYY HH:mm').format('X'))
    // Availability
    if(this.state.avail) {
      Firebase.database().ref('/sessions/'+global.uid).push({
        attendance: 2,
        client: '',
        availability: true,
        plan: true,
        group: true,
        notes: this.state.notes,
        name: this.state.sname,
        desc: this.state.desc,
        duration: duration,
        preview: this.state.preview,
        date: date,
        timestamp: parseInt(Moment(date, 'DD/MM/YYYY HH:mm').format('X')),
        uid: global.uid,
        invitees: invitees,
        groups: groups,
        memberships: memberships,
        trainerId: this.state.trainerId,
        trainerName: this.state.trainerName,
        location: location,
        locationName: locationName,
        max: 1,
        link: this.state.link,
        unlocked: unlocked,
        locked: locked,
      }).then(() => {
        global.message = lang.t('messaging:message.sessionavailabilitycreated')
        setTimeout(() => {
          EventEmitter.dispatch('updateBadges', 'session');
        }, 1000);
        this.props.history.push(this.state.back)
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    // Group Session
    } else {
      Firebase.database().ref('/sessions/'+global.uid).push({
        attendance: 2,
        client: this.state.name,
        plan: true,
        group: true,
        notes: this.state.notes,
        desc: this.state.desc,
        duration: duration,
        preview: this.state.preview,
        date: date,
        timestamp: parseInt(Moment(date, 'DD/MM/YYYY HH:mm').format('X')),
        uid: global.uid,
        invitees: invitees,
        groups: groups,
        memberships: memberships,
        trainerId: this.state.trainerId,
        trainerName: this.state.trainerName,
        location: location,
        locationName: locationName,
        max: max,
        link: this.state.link,
        unlocked: unlocked,
        locked: locked,
      }).then(() => {
        global.message = lang.t('messaging:message.classcreated')
        setTimeout(() => {
          EventEmitter.dispatch('updateBadges', 'session');
        }, 1000);
        this.props.history.push(this.state.back)
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
  }


  checkUpdateSession() {
    var passed = true
    var tmp = [false, false]
    var date = this.state.date+' '+this.state.hour+':'+this.state.mins
    var later = true
    /*if(parseInt(Moment(date, 'DD/MM/YYYY HH:mm').format('X')) < parseInt(Moment().format('X'))) {
      later = false
    }*/
    this.setState({error: [false, false]})
    if(!later) {
      tmp[0] = true
    }
    if(this.state.invited.length === 0 && this.state.private === 'private') {
      passed = false
      tmp[1] = true
    }
    if(this.state.group.length === 0 && this.state.memberships.length === 0 && this.state.private === 'group') {
      passed = false
      tmp[1] = true
    }
    this.setState({error: tmp})
    if(passed) {
      this.updateSession()
    }
  }


  updateSession() {
    var date = this.state.date+' '+this.state.hour+':'+this.state.mins
    var duration = parseInt(this.state.dur)
    if(this.state.dur === 99) {
      duration = parseInt(this.state.cdur)
    }
    var max = 0
    if(this.state.showMax) {
      max = parseInt(this.state.max)
    }
    var location = null
    var locationName = null
    if(global.locations.length > 0) {
      location = this.state.location
      locationName = ""
      for(var loc of global.locations) {
        if(loc.id === this.state.location) {
          locationName = loc.data.name
        }
      }
    }
    var invited = []
    var group = []
    var memberships = []
    if(this.state.private === 'private') {
      invited = this.state.invited
    }
    if(this.state.private === 'group') {
      for(var gr of this.state.group) {
        group.push(gr.id)
      }
    }
    if(this.state.memberships.length > 0 && this.state.private === 'group') {
      for(var pr of this.state.memberships) {
        memberships.push(pr.id)
      }
    }
    this.setState({
      editing: false
    })
    var tmp = []
    var tmpo = []
    if(this.state.item.data.clients !== undefined) {
      tmp = this.state.item.data.clients
      for(var c of this.state.item.data.clients) {
        tmpo.push(c)
      }
    } 
    var tmp2 = []
    if(this.state.item.data.waiting !== undefined) {
      tmp2 = this.state.item.data.waiting
    }
    if(max > 0 && this.state.item.data.clients !== undefined) {
      if(this.state.item.data.clients.length > max) {
        tmp = []
        for(var i=0; i<this.state.item.data.clients.length; i++) {
          if(i<max) {
            tmp.push(this.state.item.data.clients[i])
          } else {
            tmp2.push(this.state.item.data.clients[i])
          }
        }
      }
      
    }
    if((max === 0 || max > tmp.length) && this.state.item.data.waiting !== undefined) {
      var wmax = max-tmp.length
      if(wmax > this.state.item.data.waiting.length) {
        wmax = this.state.item.data.waiting.length
      }
      for(var j=0; j<wmax; j++) {
        tmp.push(tmp2[j])
      }
      tmp2.splice(0,wmax)
    }

    var sent = []
    var name = lang.t('messaging:push.thegroupsession')
    if(this.state.item.data.client !== '') {
      name = this.state.item.data.client
    }
    if(this.state.item.data.availability) {
      name = lang.t('messaging:push.theavailable')
      if(this.state.sname !== '') {
        name = this.state.sname
      }
    }
    var bookings = []
    if(this.state.item.data.bookings !== undefined) {
      bookings = this.state.item.data.bookings
    }
    if(this.state.item.data.clients !== undefined) {
      for(var t1 of tmp) {
        if(tmpo.indexOf(t1) === -1) {
          for(var cl1 of global.clients) {
            if(cl1.id === t1 && cl1.data.pushToken !== '' && cl1.data.pushToken !== undefined) {
              bookings.push(cl1.id+"||booking||trainer||"+Moment().format('X'))
              sent.push(cl1.data.pushToken)
            }
          }
        }
      }
    }
    if(sent.length > 0) {
      Connector.sendPushNotification('', global.userName, lang.t('messaging:push.nowbookedin.text2')+name+" "+Cal.getPushDate(this.state.item.data.date)+'.', 'session', this.state.item.id, sent)
    }

    var unlocked = '01/01/1900 00:00'
    if(this.state.showBook) {
      unlocked = this.state.dateBook+' '+this.state.hourBook+':'+this.state.minsBook
    }
    var lock = '01/01/2100 00:00'
    if(this.state.showBookCancel) {
      lock = this.state.dateBookCancel+' '+this.state.hourBookCancel+':'+this.state.minsBookCancel
    }
    var locked = parseInt(Moment(lock, 'DD/MM/YYYY HH:mm').format('X'))

    var sn = null
    if(this.state.avail) {
      sn = this.state.sname
    }
    Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id).update({
      client: this.state.name,
      notes: this.state.notes,
      name: sn,
      desc: this.state.desc,
      duration: duration,
      preview: this.state.preview,
      date: date,
      timestamp: parseInt(Moment(date, 'DD/MM/YYYY HH:mm').format('X')),
      max: max,
      invitees: invited,
      groups: group,
      memberships: memberships,
      trainerId: this.state.trainerId,
      trainerName: this.state.trainerName,
      location: location,
      locationName: locationName,
      link: this.state.link,
      clients: tmp,
      waiting: tmp2,
      unlocked: unlocked,
      locked: locked,
      template: '',
      bookings: bookings,
    }).then(() => {
      global.message = (this.state.avail ? lang.t('messaging:message.sessionupdated') : lang.t('messaging:message.classupdated'))

      var text = global.userName+lang.t('messaging:push.sessionupdated.text1')+name+lang.t('messaging:push.sessionupdated.text2')+Cal.getPushDate(this.state.item.data.date)+'.'
      for(var item of global.clients) {
        if(item.data.pushToken !== undefined && item.data.pushToken !== '') {
          if(this.state.item.data.clients !== undefined && this.state.timeEdit) {
            if(sent.indexOf(item.data.pushToken) === -1 && this.state.item.data.clients.indexOf(item.id) !== -1) {
              sent.push(item.data.pushToken)
            }
          }
          if(this.state.item.data.waiting !== undefined && this.state.timeEdit) {
            if(sent.indexOf(item.data.pushToken) === -1 && this.state.item.data.waiting.indexOf(item.id) !== -1) {
              sent.push(item.data.pushToken)
            }
          }
        }
      }
      if(sent.length > 0) {
        Connector.sendPushNotification('', global.userName, text, 'session', this.state.item.id, sent)
      }
      var scheduled = Moment(date, 'DD/MM/YYYY HH:mm').add(-global.userSessionReminder, 'hours').format('X')
      for(var sitem of global.schedule) {
        if(sitem.id === this.state.item.id+'-push') {
          if(Moment(scheduled, 'X') > Moment()) {
            Firebase.database().ref('/schedule/'+sitem.id).update({
              timestamp: parseInt(Moment(date, 'DD/MM/YYYY HH:mm').add(-global.userSessionReminder, 'hours').format('X')),
              desc: lang.t('messaging:push.bookedin.text4')+name+" "+this.getSpecialDate(date, scheduled)+".",
            })
          } else {
            Firebase.database().ref('/schedule/'+sitem.id).remove()
          }
          
        }
        if(sitem.id === this.state.item.id+'-email') {
          if(Moment(scheduled, 'X') > Moment()) {
            Firebase.database().ref('/schedule/'+sitem.id).update({
              timestamp: parseInt(Moment(date, 'DD/MM/YYYY HH:mm').add(-global.userSessionReminder, 'hours').format('X')),
              type: this.getSpecialDate(date, scheduled),
              desc: name
            })
          } else {
            Firebase.database().ref('/schedule/'+sitem.id).remove()
          }
        }
      }
      this.props.history.push(this.state.back)
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  getSpecialDate(date1, date2) {
    var label = Moment(date1, 'DD/MM/YYYY HH:mm').locale(lang.language).format('llll')
    if(Moment(date1, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY') === Moment(date2, 'X').format('DD/MM/YYYY')) {
      label = 'today '+Moment(date1, 'DD/MM/YYYY HH:mm').locale(lang.language).format('LT')
    }
    if(Moment(date1, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY') === Moment(date2, 'X').add(1, 'days').format('DD/MM/YYYY')) {
      label = 'tomorrow '+Moment(date1, 'DD/MM/YYYY HH:mm').locale(lang.language).format('LT')
    }
    return label
  }


  removeItem(item) {
    if(this.state.private === 'private') {
      var tmp1 = this.state.invited
      if(tmp1.length > 0) {
        for(var i=0; i<tmp1.length; i++) {
          if(tmp1[i] === item) {
            tmp1.splice(i, 1)
          }
        }
      }
      this.setState({
        invited: tmp1
      })
    } else {
      var tmp2 = this.state.group
      if(tmp2.length > 0) {
        for(var j=0; j<tmp2.length; j++) {
          if(tmp2[j] === item) {
            tmp2.splice(j, 1)
          }
        }
      }
      var tmp3 = this.state.memberships
      if(tmp3.length > 0) {
        for(var k=0; k<tmp3.length; k++) {
          if(tmp3[k] === item) {
            tmp3.splice(k, 1)
          }
        }
      }
      this.setState({
        group: tmp2,
        memberships: tmp3,
      })
    }
  }



  // Modals ------------------------------------------------------------



  showModalRemove(item) {
    this.setState({
      showModalRemove: true,
      remove: item
    })
  }


  hideModals() {
    this.setState({
      showModalClients: false,
      showModalGroups: false,
      showModalMemberships: false,
      showModalRemove: false,
    })
  }


  selectClients(items) {
    this.hideModals()
    var tmp = []
    for(var item of items) {
      tmp.push(item)
    }
    this.setState({
      invited: tmp,
    })
  }


  selectGroups(items) {
    this.hideModals()
    var tmp = []
    for(var item of items) {
      tmp.push(item)
    }
    this.setState({
      group: tmp,
    })
  }


  selectMemberships(items) {
    this.hideModals()
    var tmp = []
    for(var item of items) {
      tmp.push(item)
    }
    this.setState({
      memberships: tmp,
    })
  }



  // Display stuff ------------------------------------------------------------



  renderSelect() {
    if(!this.state.avail) {
      return (
        <div className="col-12">
          <InputInvited type={this.state.private} clients={this.state.invited} groups={this.state.group} memberships={this.state.memberships} error={this.state.error[1]} clickClients={() => this.setState({showModalClients: true})} clickGroups={() => this.setState({showModalGroups: true})} clickMemberships={() => this.setState({showModalMemberships: true})} clickRemove={(event) => this.removeItem(event)}/>
          <div className="sv-10"></div>
        </div>
      )
    }
  }


  renderBook() {
    if(this.state.showBook) {
      return <InputDatetime datelabel={lang.t('calendar:form.open.field')} hour={this.state.hourBook} min={this.state.minsBook} date={this.state.dateBook} setDay={(event) => this.setState({dateBook: event})} setHour={(event) => this.setState({hourBook: event})} setMin={(event) => this.setState({minsBook: event})}/>
    }
  }


  renderBookCancel() {
    if(this.state.showBookCancel) {
      return <InputDatetime datelabel={lang.t('calendar:form.close.field')} hour={this.state.hourBookCancel} min={this.state.minsBookCancel} date={this.state.dateBookCancel} setDay={(event) => this.setState({dateBookCancel: event})} setHour={(event) => this.setState({hourBookCancel: event})} setMin={(event) => this.setState({minsBookCancel: event})}/>
    }
  }


  renderMax() {
    if(this.state.showMax && !this.state.avail) {
      return <InputNumber label={lang.t('calendar:form.spots.field')} placeholder={lang.t('calendar:form.spots.placeholder')} value={this.state.max} onChange={(event) => this.setState({max: event})}/>
    }
  }


  renderLimit() {
    if(!this.state.avail) {
      return <InputToggle label={lang.t('calendar:form.spots.label')} value={this.state.showMax} onChange={() => this.setState({showMax: !this.state.showMax})} text={lang.t('calendar:form.spots.text')}/>
    }
  }


  renderSessionNameAvail() {
    if(this.state.avail) {
      return <InputText label={lang.t('calendar:form.sessionname2')} value={this.state.sname} onChange={(event) => this.setState({sname: event})}/>
    }
  }


  renderMoreOptions() {
    if(this.state.showMore) {
      return (
        <div>
          <div className="sv-10"></div>
          <button className="btn tertiary small width-12 sub" onClick={() => this.setState({showMore: false})}>{lang.t('calendar:button.hidemoreoptions')}</button>
          <div className="sv-20 clear"></div>
          {this.renderSessionNameAvail()}
          <InputText label={this.state.avail ? lang.t('calendar:form.sessiondescription') : lang.t('calendar:form.classdescription')} value={this.state.desc} onChange={(event) => this.setState({desc: event})}/>
          <InputText label={lang.t('calendar:form.notes')} value={this.state.notes} onChange={(event) => this.setState({notes: event})}/>
          <InputToggle label={lang.t('calendar:form.preview.label')} value={this.state.preview} onChange={() => this.setState({preview: !this.state.preview})} text={this.state.avail ? lang.t('calendar:form.preview.text') : lang.t('calendar:form.preview.text2')}/>
          {this.renderLimit()}
          {this.renderMax()}
          <InputToggle label={lang.t('calendar:form.open.label')} value={this.state.showBook} onChange={() => this.setState({showBook: !this.state.showBook})} text={lang.t('calendar:form.open.text')}/>
          {this.renderBook()}
          <InputToggle label={lang.t('calendar:form.close.label')} value={this.state.showBookCancel} onChange={() => this.setState({showBookCancel: !this.state.showBookCancel})} text={this.state.avail ? lang.t('calendar:form.close.text') : lang.t('calendar:form.close.text2')}/>
          {this.renderBookCancel()}
        </div>
      )
    } else {
      return (
        <div>
          <div className="sv-10"></div>
          <button className="btn tertiary small width-12 add" onClick={() => this.setState({showMore: true})}>{lang.t('calendar:button.showmoreoptions')}</button>
        </div>
      )
    }
  }


  renderLocation() {
    if(global.locations.length > 0 && HelperBusiness.getRoleIncl('trainer,admin')) {
      return <Select label={lang.t('calendar:form.location')} value={this.state.location} values={this.state.locations} onChange={(event) => this.setState({location: event})}/>
    }
  }

  
  renderTrainer() {
    if(this.state.staff.length > 0 && HelperBusiness.getRoleIncl('')) {
      return <Select label={lang.t('calendar:form.trainer')} value={this.state.trainerId} values={this.state.trainers} onChange={(event) => this.setTrainer(event)}/>
    }
  }


  renderComments() {
    var id = 'calavail'
    if(!this.state.avail) {
      id = 'calgroup1'
      if(this.state.private === 'group') {
        id = 'calgroup2'
      }
      if(this.state.private === 'private') {
        id = 'calgroup3'
      }
    }
    if(this.state.editing) {
      id += 'edit'
    }
    return <WidgetFormInfo id={id}/>
  }


  renderButton() {
    if(this.state.editing) {
      return (
        <div>
          <button className="btn primary mb-20" onClick={() => this.checkUpdateSession()}>{lang.t('common:button.savechanges')}</button>
          <Link to={this.state.back} className="btn tertiary close width-12">{lang.t('common:button.cancel')}</Link>
        </div>
      )
    } else {
      return <button className="btn primary" onClick={() => this.checkCreateSession()}>{this.state.avail ? lang.t('calendar:button.createsession') : lang.t('calendar:button.createclass')}</button>
    }
  }


  renderTabs() {
    if(this.state.avail) {
      return (
        <div className="radios tabs">
          <button className="radio col-6" onClick={() => this.props.history.push(ROUTES.NEWSESSION)}>{lang.t('calendar:form.11session')}</button>
          <button className="radio col-6 active">{lang.t('calendar:form.11availability')}</button>
        </div>
      )
    } else {
      return (
        <div>
          <div className="radios tabs">
            <button className={this.state.private === '' ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.setState({private: ''})}>{lang.t('calendar:form.public')}</button>
            <button className={this.state.private === 'group' ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.setState({private: 'group'})}>{lang.t('calendar:form.exclusive')}</button>
            <button className={this.state.private === 'private' ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.setState({private: 'private'})}>{lang.t('calendar:form.private')}</button>
          </div>
        </div>
      )
    }
  }


  renderSessionName() {
    if(!this.state.avail) {
      return (
        <InputText label={lang.t('calendar:form.sessionname')} value={this.state.name} onChange={(event) => this.setState({name: event})}/>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content form-sidebar withtabs">
            <div className="col-6 mt-20">
              <div className="box withtabs">
                {this.renderTabs()}
                <div className="inner">
                  {this.renderSelect()}
                  <div className="sv-20 clear"></div>
                  <InputDatetime hour={this.state.hour} min={this.state.mins} date={this.state.date} setDay={(event) => this.setState({date: event, timeEdit: true})} setHour={(event) => this.setState({hour: event, timeEdit: true})} setMin={(event) => this.setState({mins: event, timeEdit: true})}/>
                  <div className="col-12">
                    <InputDuration duration={this.state.dur} custom={this.state.cdur} setDuration={(event) => this.setState({dur: event})} setCustom={(event) => this.setState({cdur: event})}/>
                    {this.renderSessionName()}
                    {this.renderTrainer()}
                    {this.renderLocation()}
                    <InputText label={lang.t('calendar:form.virtual')} placeholder='Zoom, Hangouts, Skype...' value={this.state.link} onChange={(event) => this.setState({link: event})}/>
                    {this.renderMoreOptions()}
                  </div>
                  <div className="clear sv-30"></div>
                  {this.renderButton()}
                </div>
              </div>
            </div>
            <div className="col-6 mt-20">
              <div className="sidebar">
                {this.renderComments()}
              </div>
            </div>
            <div className="clear sv-40"></div>
          </div>
          <div className="btn-news" onClick={() => window.Intercom('startTour', '318127')}>{lang.t('common:label.showmearound')}</div>
          <Header title={this.state.editing ? lang.t('calendar:title.editclass') : lang.t('calendar:title.newsession')} link={this.state.back} type={this.state.editing ? 'simple' : 'sessions'} active={this.state.avail ? '11' : 'group'}/>
          <Navigation active='calendar' />
          <ModalClients show={this.state.showModalClients} title={lang.t('modals:clients.title.selectclients')} button={lang.t('modals:clients.button.confirmselection')} multiple={true} selected={this.state.invited} onHide={() => this.hideModals()} clickMainButton={(event) => this.selectClients(event)}/>
          <ModalItems show={this.state.showModalGroups} items={global.clientGroups} title={lang.t('modals:items.title.selectgroups')} button={lang.t('modals:clients.button.confirmselection')} multiple={true} selected={this.state.group} onHide={() => this.hideModals()} clickMainButton={(event) => this.selectGroups(event)}/>
          <ModalItems show={this.state.showModalMemberships} items={this.state.products} type='memberships' empty={lang.t('modals:items.empty.nomemberships')} title={lang.t('modals:items.title.packsandmemberships')} button={lang.t('modals:clients.button.confirmselection')} multiple={true} selected={this.state.memberships} onHide={() => this.hideModals()} clickMainButton={(event) => this.selectMemberships(event)}/>
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='calendar-locked' type='simple'/>
          </div>
          <Header title={lang.t('calendar:title.newsession')} link={ROUTES.CALENDAR} type='simple'/>
          <Navigation active='calendar' />
        </div>
      )
    }
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['calendar','common','modals','messaging'])(withRouter(withAuthorization(condition)(NewGroupPage)));