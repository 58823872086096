import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import 'moment/locale/es';
import Resizer from "react-image-file-resizer";
import Connector from '../../data/Connector';
import Cal from '../../helper/Calendar';
import HelperClients from '../../helper/Clients';
import HelperEmpty from '../../helper/Empty';
import HelperBusiness from '../../helper/Business';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import HelperEmptyImg from '../../helper/EmptyLarge';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import ListEmpty from '../../components/List/empty';
import ModalMessageSimple from '../../components/Modals/message-simple';
import ModalForm from '../../components/Modals/form';
import InputFile from '../../components/Form/input-file';
import InputDate from '../../components/Form/input-date';
import InputToggle from '../../components/Form/toggle';
import Textarea from '../../components/Form/textarea';
import Select from '../../components/Form/select';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';


class CommunityPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: global.clients,
      community: global.community,
      images: global.communityImages,
      sessions: global.sessions,
      dateRange: global.dateRange,
      today: [],
      tomorrow: [],
      showSessions: 'today',
      birthdays: [],
      value: '',
      file: null,
      fileDisplay: null,
      id: '',
      post: '',
      image: '',
      newPost: false,
      schedule: false,
      date: Moment().format('YYYY-MM-DD'),
      min: '00',
      sec: '00',
      hidden: 'hidden',
      margin: 0,
      showModalPost: false,
      showModalReply: false,
      showModalReplyEdit: false,
      showModalDeleteReply: false,
      showModalDeletePost: false,
    };
  }


  componentDidMount() {
    HelperPage.initialize('Community - PT Mate', global.currentPage)
    HelperPage.checkMessage()
    this._isMounted = true
    
    EventEmitter.subscribe('communityLoaded', (event) => {
      this.setState({
        community: global.community
      }, () => {
        this.getImages()
      })
    })
    EventEmitter.subscribe('communityImagesLoaded', (event) => {
      this.setState({images: global.communityImages})
    })
    EventEmitter.subscribe('clientsLoaded', (event) => {
      this.setState({clients: global.clients})
      this.setBirthdays()
    })
    EventEmitter.subscribe('sessionsLoaded', (event) => {
      this.setState({sessions: global.sessions})
      this.setCalendar()
    })
    EventEmitter.subscribe('userLoaded', (event) => {
      this.setBirthdays()
    })
    EventEmitter.subscribe('userDataLoaded', (event) => {
      this.setBirthdays()
    })
    EventEmitter.subscribe('locationSet', (event) => {
      this.setCalendar()
      this.setBirthdays()
    })
    this.setCalendar()
    this.setBirthdays()
    this.getImages()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  getImages() {
    if(this._isMounted) {
      for(var item of this.state.community) {
        if(item.data.image !== '') {
          var add = true
          for(var img of this.state.images) {
            if(img.id === item.id) {
              add = false
            }
          }
          if(add) {
            Connector.loadCommunityImage(item.id, item.data.image)
          }
        }
      }
    }
  }


  setCalendar() {
    if(this._isMounted) {
      var tmp1 = []
      var tmp2 = []
      for(var item of global.sessions) {
        if(global.loc === '' || item.data.location === global.loc) {
          if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY') === Moment().format('DD/MM/YYYY') && Moment(item.data.date, 'DD/MM/YYYY HH:mm').add(item.data.duration, 'minutes') > Moment()) {
            if(HelperBusiness.getRole('assistant,assistant2')) {
              if(item.data.trainerId === this.state.trainerId) {
                tmp1.push(item)
              }
            } else {
              tmp1.push(item)
            }
          } else if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY') === Moment().add(1, 'day').format('DD/MM/YYYY')) {
            if(HelperBusiness.getRole('assistant,assistant2')) {
              if(item.data.trainerId === this.state.trainerId) {
                tmp2.push(item)
              } 
            } else {
              tmp2.push(item)
            }
          }
        }
      }
      this.setState({
        today: tmp1,
        tomorrow: tmp2
      })
    }
  }


  setBirthdays() {
    if(this._isMounted) {
      var tmp = []
      var list = []
      if(global.loc === '') {
        for(var cl of global.clients) {
          list.push(cl.id)
        }
        for(var cl2 of global.clientsInactive) {
          list.push(cl2.id)
        }
      } else {
        for(var loc of global.locations) {
          if(loc.id === global.loc && loc.data.clients !== undefined) {
            list = loc.data.clients
          }
        }
      }

      for(var client of this.state.clients) {
        if(list.indexOf(client.id) !== -1) {
          let day = Moment(client.data.birth, 'DD/MM/YYYY').format('DD/MM')
          let yr = Moment().format('YYYY')
          let date = Moment(day+'/'+yr, 'DD/MM/YYYY').format('X')
          let advance = Moment().add(5, 'days').format('X')
          if((date < advance && date >= Moment().format('X')) || day === Moment().format('DD/MM')) {
            tmp.push({
              id: client.id,
              title: client.data.name,
              desc: Moment(client.data.birth, 'DD/MM/YYYY').format('D MMMM'),
              link: '/clients/'+client.id+'/overview',
            })
          }
        }
      }
      this.setState({
        birthdays: tmp,
      })
    }
  }


  setImage(file) {
    this.setState({
      file: file,
      fileDisplay:  URL.createObjectURL(file)
    })
  }



  // Calendar & Notifications ------------------------------------------------------------



  getSessionLine1(item) {
    var label = ''
    if(item.data.group) {
      label = lang.t('home:act.groupsession')
      if(item.data.client !== '') {
        label = item.data.client
      }
      if(item.data.availability !== undefined) {
        label = lang.t('home:act.availabe11session')
      }
    } else {
      label = this.getClientName(item.data.client)
    }
    return label
  }


  getSessionLine2(item) {
    var label = ''
    if(item.data.group) {
      if(item.data.clients === undefined) {
        label = lang.t('home:label.nobookingsyet')+' - '+Cal.getDuration(item.data.duration)
      } else {
        label = item.data.clients.length+' '+lang.t('home:label.bookedin')+' - '+Cal.getDuration(item.data.duration)
      }
      if(item.data.max !== 0 && item.data.max !== undefined && item.data.availability === undefined) {
        label += ' ('+item.data.max+' max)'
      }
    } else {
      label = lang.t('home:actclient.11session')+' - '+Cal.getDuration(item.data.duration)
    }
    return label
  }


  getClientName(id) {
    var name = ''
    for(var item of this.state.clients) {
      if(item.id === id) {
        name = item.data.name
      }
    }
    for(var inact of global.clientsInactive) {
      if(inact.id === id) {
        name = item.data.name
      }
    }
    return name
  }


  selectSession(id) {
    global.currentSession = id;
  }


  getLock(item) {
    var label = ''
    if(item.data.invitees !== undefined && item.data.invitees.length > 0) {
      label = 'lock'
    }
    return label
  }


  getName(id) {
    var label = 'Poster'
    if(id === global.uidUser) {
      label = lang.t('common:label.you')
    }
    for(var item of this.state.clients) {
      if(item.data.uid === id) {
        label = item.data.name
      }
    }
    return label
  }


  getDate(date) {
    var td= Moment().format('DD/MM/YYYY')
    var yd= Moment().add(-1, 'days').format('DD/MM/YYYY')
    var dt =Moment(date, 'X').format('DD/MM/YYYY')
    var label = Moment(date, 'X').locale(lang.language).format('D MMM YYYY LT')
    if(dt === td) {
      label = lang.t('home:label.today')+' '+Moment(date, 'X').locale(lang.language).format('LT')
    }
    if(dt === yd) {
      label = lang.t('home:label.yesterday')+' '+Moment(date, 'X').locale(lang.language).format('LT')
    }
    return label
  }


  addReaction(item, type, parent) {
    var tmp = ''
    var add = true
    if(type === 'like') {
      if(item.data.reaction1.indexOf(global.uid) !== -1) {
        tmp = item.data.reaction1
        add = false
      } else {
        item.data.reaction1 += ','+global.uid
      }
      
    }
    if(type === 'party') {
      if(item.data.reaction2.indexOf(global.uid) !== -1) {
        tmp = item.data.reaction2
        add = false
      } else {
        item.data.reaction2 += ','+global.uid
      }
    }
    if(type === 'smile') {
      if(item.data.reaction3.indexOf(global.uid) !== -1) {
        tmp = item.data.reaction3
        add = false
      } else {
        item.data.reaction3 += ','+global.uid
      }
    }
    if(type === 'sad') {
      if(item.data.reaction4.indexOf(global.uid) !== -1) {
        tmp = item.data.reaction4
        add = false
      } else {
        item.data.reaction4 += ','+global.uid
      }
    }
    if(!add) {
      var ar = tmp.split(',')
      ar.splice(0,1)
      for(var i=0; i<ar.length; i++) {
        if(ar[i] === global.uid) {
          ar.splice(i, 1)
        }
      }
      tmp = ''
      for(var a of ar) {
        tmp += ','+a
      }
      if(type === 'like') { item.data.reaction1 = tmp }
      if(type === 'party') { item.data.reaction2 = tmp }
      if(type === 'smile') { item.data.reaction3 = tmp }
      if(type === 'sad') { item.data.reaction4 = tmp }
    }
    if(parent === '') {
      Firebase.database().ref('/community/'+global.uid+'/'+item.id).update({
        reaction1: item.data.reaction1,
        reaction2: item.data.reaction2,
        reaction3: item.data.reaction3,
        reaction4: item.data.reaction4,
      })
    } else {
      Firebase.database().ref('/community/'+global.uid+'/'+parent+'/comments/'+item.id).update({
        reaction1: item.data.reaction1,
        reaction2: item.data.reaction2,
        reaction3: item.data.reaction3,
        reaction4: item.data.reaction4,
      })
    }
  }



  // Modals ------------------------------------------------------------



  showModalPost(text, id, image, date) {
    if(text === '') {
      this.setState({
        showModalPost: true,
        value: '',
        file: null,
        fileDisplay: null,
        mode: 'new',
        id: '',
        image: image,
        date: Moment().format('YYYY-MM-DD'),
        min: '00',
        sec: '00',
        newPost: true,
        schedule: false,
      });
    } else {
      var tmp = false
      if(Moment(date, 'X') > Moment()) {
        tmp = true
      }
      this.setState({
        showModalPost: true,
        value: text,
        file: null,
        fileDisplay: null,
        mode: 'edit',
        id: id,
        image: image,
        newPost: false,
        schedule: tmp,
        date: Moment(date, 'X').format('YYYY-MM-DD'),
        min: Moment(date, 'X').format('HH'),
        sec: Moment(date, 'X').format('mm'),
      });
    }
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2
      });
    }, 100);
  }


  showModalReply(text, id, post) {
    if(text === '') {
      this.setState({
        value: '',
        id: '',
        post: post,
        showModalReply: true
      });
    } else {
      this.setState({
        value: text,
        id: id,
        post: post,
        showModalReplyEdit: true
      });
    }
  }


  showModalDelete(id, parent, image) {
    if(parent === '') {
      this.setState({
        showModalDeletePost: true,
        id: id,
        post: parent,
        image: image
      })
    } else {
      this.setState({
        showModalDeleteReply: true,
        id: id,
        post: parent,
        image: image
      })
    }
  }


  hideModals() {
    this.setState({
      showModalReply: false,
      showModalReplyEdit: false,
      showModalDeleteReply: false,
      showModalDeletePost: false,
    })
  }


  createPost() {
    if(this.state.value !== '') {
      this.hideModalPost()
      if(!this.state.schedule) {
        var obj = Firebase.database().ref('/community/'+global.uid).push()
        obj.set({
          text: this.state.value,
          author: global.uidUser,
          date: parseInt(Moment().format('X')),
          image: '',
          reaction1: '',
          reaction2: '',
          reaction3: '',
          reaction4: '',
        }).then(() => {
          if(this.state.file !== null) {
            const sr = Firebase.storage().ref()
            const fr = sr.child('images/community/'+global.uid+'/'+obj.key+'.jpg')
            Resizer.imageFileResizer(
              this.state.file,
              800,
              800,
              "JPEG",
              70,
              0,
              (uri) => {
                var ar = uri.split(',')
                fr.putString(ar[1], "base64", {contentType: 'image/jpg'}).then(() => {
                  Firebase.database().ref('/community/'+global.uid+'/'+obj.key).update({
                    image: 'images/community/'+global.uid+'/'+obj.key+'.jpg',
                  })
                }).catch((error)=>{
                  EventEmitter.dispatch('showMessageError', error.message);
                })
              },
              "base64",
              200,
              200
            );
          }
          var sent = []
          var text = global.userName+lang.t('messaging:push.community.text')
          for(var item of global.clients) {
            if(item.data.pushToken !== undefined && item.data.pushToken !== '') {
              if(sent.indexOf(item.token) === -1) {
                sent.push(item.data.pushToken)
              }
            }
          }
          if(sent.length > 0) {
            Connector.sendPushNotification('', global.userName, text, 'post', '', sent)
          }
          EventEmitter.dispatch('showMessage', lang.t('messaging:message.postcreated'));
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
      } else {
        // Schedule
        var obj2 = Firebase.database().ref('/community/'+global.uid).push()
        obj2.set({
          text: this.state.value,
          author: global.uidUser,
          date: parseInt(Moment(this.state.date+' '+this.state.min+':'+this.state.sec, 'YYYY-MM-DD HH:mm').format('X')),
          image: '',
          reaction1: '',
          reaction2: '',
          reaction3: '',
          reaction4: '',
        }).then(() => {
          if(this.state.file !== null) {
            const sr = Firebase.storage().ref()
            const fr = sr.child('images/community/'+global.uid+'/'+obj2.key+'.jpg')
            Resizer.imageFileResizer(
              this.state.file,
              800,
              800,
              "JPEG",
              70,
              0,
              (uri) => {
                var ar = uri.split(',')
                fr.putString(ar[1], "base64", {contentType: 'image/jpg'}).then(() => {
                  Firebase.database().ref('/community/'+global.uid+'/'+obj2.key).update({
                    image: 'images/community/'+global.uid+'/'+obj2.key+'.jpg',
                  })
                }).catch((error)=>{
                  EventEmitter.dispatch('showMessageError', error.message);
                })
              },
              "base64",
              200,
              200
            );
          }
          var sent = []
          var text = global.userName+lang.t('messaging:push.community.text')
          for(var item of global.clients) {
            if(item.data.pushToken !== undefined && item.data.pushToken !== '') {
              if(sent.indexOf(item.token) === -1) {
                sent.push(item.data.pushToken)
              }
            }
          }
          // Create push entry
          Firebase.database().ref('/schedule/'+obj2.key).update({
            title: global.userName,
            desc: text,
            iid: obj2.key,
            type: 'post',
            uid: global.uid,
            timestamp: parseInt(Moment(this.state.date+' '+this.state.min+':'+this.state.sec, 'YYYY-MM-DD HH:mm').format('X')),
            tokens: sent,
            message: 'push'
          })
          EventEmitter.dispatch('showMessage', lang.t('messaging:message.postcreated'));
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
      }
    }
  }


  updatePost() {
    if(this.state.value !== '') {
      this.hideModalPost()
      var hasImage = false
      var date = Moment(this.state.date+' '+this.state.min+':'+this.state.sec).format('X')
      if(this.state.file !== null) {
        var tmp = []
        for(var img of global.communityImages) {
          if(img.id !== this.state.id) {
            tmp.push(img)
          }
          if(img.id === this.state.id) {
            hasImage = true
          } 
        }
        if(hasImage) {
          // Replace image
          Firebase.storage().ref('/images/community/'+global.uid+'/'+this.state.id+'.jpg').delete()
          global.communityImages = tmp
          this.setState({
            images: tmp
          }, () => {
            Firebase.database().ref('/community/'+global.uid+'/'+this.state.id).update({
              text: this.state.value,
              date: parseInt(date)
            }).then(() => {
              if(this.state.file !== null) {
                const sr = Firebase.storage().ref()
                const fr = sr.child('images/community/'+global.uid+'/'+this.state.id+'.jpg')
                Resizer.imageFileResizer(
                  this.state.file,
                  800,
                  800,
                  "JPEG",
                  70,
                  0,
                  (uri) => {
                    var ar = uri.split(',')
                    fr.putString(ar[1], "base64", {contentType: 'image/jpg'}).then(() => {
                      Firebase.database().ref('/community/'+global.uid+'/'+this.state.id).update({
                        image: 'images/community/'+global.uid+'/'+this.state.id+'.jpg',
                      })
                    }).catch((error)=>{
                      EventEmitter.dispatch('showMessageError', error.message);
                    })
                  },
                  "base64",
                  200,
                  200
                );  
              }
            }).catch((error)=>{
              EventEmitter.dispatch('showMessageError', error.message);
            })
          })
        } else {
          // Don't replace image
          Firebase.database().ref('/community/'+global.uid+'/'+this.state.id).update({
            text: this.state.value,
            date: date
          }).then(() => {
            if(this.state.file !== null) {
              const sr = Firebase.storage().ref()
              const fr = sr.child('images/community/'+global.uid+'/'+this.state.id+'.jpg')
              Resizer.imageFileResizer(
                this.state.file,
                800,
                800,
                "JPEG",
                70,
                0,
                (uri) => {
                  var ar = uri.split(',')
                  fr.putString(ar[1], "base64", {contentType: 'image/jpg'}).then(() => {
                    Firebase.database().ref('/community/'+global.uid+'/'+this.state.id).update({
                      image: 'images/community/'+global.uid+'/'+this.state.id+'.jpg',
                    })
                  }).catch((error)=>{
                    EventEmitter.dispatch('showMessageError', error.message);
                  })
                },
                "base64",
                200,
                200
              );  
            }
          }).catch((error)=>{
            EventEmitter.dispatch('showMessageError', error.message);
          })
        }
        
      } else {
        Firebase.database().ref('/community/'+global.uid+'/'+this.state.id).update({
          text: this.state.value,
          date: date
        }).then(() => {
          EventEmitter.dispatch('showMessage', lang.t('messaging:message.postupdated'));
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
      }
      if(Moment(date, 'X') > Moment()) {
        // Update push entry
        var sent = []
        for(var item of global.clients) {
          if(item.data.pushToken !== undefined && item.data.pushToken !== '') {
            if(sent.indexOf(item.token) === -1) {
              sent.push(item.data.pushToken)
            }
          }
        }
        Firebase.database().ref('/schedule/'+this.state.id).update({
          timestamp: parseInt(date),
          tokens: sent,
        })
      }
    }
  }


  createReply() {
    if(this.state.value !== '') {
      this.hideModals()
      Firebase.database().ref('/community/'+global.uid+'/'+this.state.post+'/comments').push({
        text: this.state.value,
        author: global.uidUser,
        date: parseInt(Moment().format('X')),
        reaction1: '',
        reaction2: '',
        reaction3: '',
        reaction4: '',
      }).then(() => {
        EventEmitter.dispatch('showMessage', lang.t('messaging:message.replyposted'));
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
      var sent = []
      var text = global.userName+lang.t('messaging:push.community.text2')
      
      for(var item of global.clients) {
        if(item.data.pushToken !== undefined && item.data.pushToken !== '') {
          if(sent.indexOf(item.token) === -1) {
            sent.push(item.data.pushToken)
          }
        }
      }
      if(sent.length > 0) {
        Connector.sendPushNotification('', global.userName, text, 'reply', '', sent)
      }
    }
  }


  updateReply() {
    if(this.state.value !== '') {
      this.hideModals()
      Firebase.database().ref('/community/'+global.uid+'/'+this.state.post+'/comments/'+this.state.id).update({
        text: this.state.value,
      }).then(() => {
        EventEmitter.dispatch('showMessage', lang.t('messaging:message.replyupdated'));
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
  }


  deletePost() {
    this.hideModals()
    if(this.state.image !== '') {
      Firebase.storage().ref('/images/community/'+global.uid+'/'+this.state.id+'.jpg').delete()
    }
    Firebase.database().ref('/community/'+global.uid+'/'+this.state.id).remove()
    EventEmitter.dispatch('showMessage', lang.t('messaging:message.postdeleted'));
    var scheduled = false
    for(var item of global.community) {
      if(item.id === this.state.id && Moment(item.data.date) > Moment()) {
        scheduled = true
      }
    }
    if(scheduled) {
      // Update push entry
      Firebase.database().ref('/schedule/'+this.state.id).remove()
    }
  }


  deleteReply() {
    this.hideModals()
    Firebase.database().ref('/community/'+global.uid+'/'+this.state.post+'/comments/'+this.state.id).remove()
    EventEmitter.dispatch('showMessage', lang.t('messaging:message.replydeleted'));
  }



  // Schedule modal ------------------------------------------------------------



  hideModalPost() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showModalPost: false});
    }, 500);
  }


  renderModalSchedule() {
    if(this.state.schedule) {
      return (
        <div>
          <div className="col-6">
            <InputDate label={lang.t('modals:form.data9.date')} value={this.state.date} format='YYYY-MM-DD' min={Moment().format('YYYY-MM-DD')} onChange={(event) => this.setState({date: Moment(event).format('YYYY-MM-DD')})}/>
          </div>
          <div className="col-3">
            <Select label={lang.t('modals:form.data9.hour')} value={this.state.min} values={[{name: '00', value: '00'}, {name: '01', value: '01'}, {name: '02', value: '02'}, {name: '03', value: '03'}, {name: '04', value: '04'}, {name: '05', value: '05'}, {name: '06', value: '06'}, {name: '07', value: '07'}, {name: '08', value: '08'}, {name: '09', value: '09'}, {name: '10', value: '10'}, {name: '11', value: '11'}, {name: '12', value: '12'}, {name: '13', value: '13'}, {name: '14', value: '14'}, {name: '15', value: '15'}, {name: '16', value: '16'}, {name: '17', value: '17'}, {name: '18', value: '18'}, {name: '19', value: '19'}, {name: '20', value: '20'}, {name: '21', value: '21'}, {name: '22', value: '22'}, {name: '23', value: '23'}]} onChange={(event) => this.setState({min: event})}/>
          </div>
          <div className="col-3">
            <Select label={lang.t('modals:form.data9.min')} value={this.state.sec} values={[{name: '00', value: '00'}, {name: '15', value: '15'}, {name: '30', value: '30'}, {name: '45', value: '45'}]} onChange={(event) => this.setState({sec: event})}/>
          </div>
          <div className="clear sv-10"></div>
        </div>
      )
    }
  }


  renderModalToggle() {
    if(this.state.newPost) {
      return <InputToggle label={lang.t('modals:form.data9.schedule')} value={this.state.schedule} onChange={(event) => this.setState({schedule: !this.state.schedule})} text={lang.t('modals:form.data9.schedule.text')}/>
    }
  }


  renderModalPost() {
    if(this.state.showModalPost) {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{this.state.newPost ? lang.t('modals:form.data9.title') : lang.t('modals:form.data10.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideModalPost()}>{lang.t('common:button.close')}</button>
            <Textarea label={lang.t('modals:form.data9.label1')} value={this.state.value} onChange={(event) => this.setState({value: event})}/>
            <InputFile label={lang.t('modals:form.data9.label2')} onChange={(event) => this.setState({file: event})}/>
            {this.renderModalToggle()}
            {this.renderModalSchedule()}
            <div className="sv-10"></div>
            <button className="btn primary" onClick={() => (this.state.newPost ? this.createPost() : this.updatePost())}>{this.state.newPost ? lang.t('modals:form.data9.button') : lang.t('modals:form.data10.button')}</button>
          </div>
        </div>
      )
    }
  }



  // Display stuff ------------------------------------------------------------



  renderSession(item) {
    if(item.data.group) {
      if(item.data.availability === undefined) {
        return (
          <div className="icon primary">
            <div className="inner group"></div>
          </div>
        )
      } else {
        return (
          <div className="icon secondary">
            <div className="inner session"></div>
          </div>
        )
      }
    } else {
      return (
        <div className="icon secondary">
          <div className="inner session"></div>
        </div>
      )
    }
  }

  
  renderAvatarBday(item) {
    for(var client of this.state.clients) {
      if(client.id === item.id) {
        return (
          HelperClients.getImage(client)
        )
      }
    }
  }


  renderActions() {
    if(HelperBusiness.getRoleIncl('trainer,admin,finance,cs')) {
      return (
        <div className="mb-50">
          <div className="action" onClick={() => this.showModalPost('', '', '', Moment().format('X'))}>{lang.t('actions:createnewpost')}</div>
        </div>
      )
    }
  }


  renderToday() {
    if(this.state.showSessions === 'today') {
      if(this.state.today.length === 0) {
        return (
          <div className="empty small clear">
            {HelperEmpty.renderImage('calendar')}
            <h4>{lang.t('home:label.nothingcomingup')}</h4>
          </div>
        )
      } else {
        var list = this.state.today
        list.sort((a,b) => Moment(a.data.date, 'DD/MM/YYYY HH:mm') - Moment(b.data.date, 'DD/MM/YYYY HH:mm'));
        return (
          <div className="clear">
            {list.map(item => (
              <div className="list bare" key={item.id}>
                <Link to={!item.data.group ? '/calendar/session/'+item.id : (item.data.availability === undefined ? '/calendar/group-session/'+item.id : '/calendar/availability/'+item.id)} onClick={() => this.selectSession(item.id)}>
                  {this.renderSession(item)}
                  <div className="main">
                    <h4>{Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('LT')}</h4>
                    <p>{this.getSessionLine1(item)}<br/>{this.getSessionLine2(item)}</p>
                  </div>
                  <div className="clear"></div>
                </Link>
              </div>
            ))}
            <div className="sv-10"></div>
            <Link to={ROUTES.CALENDAR} className="btn tertiary width-12 small">{lang.t('common:button.viewall')}</Link>
          </div>
        );
      }
    }
  }


  renderTomorrow() {
    if(this.state.showSessions === 'tomorrow') {
      if(this.state.tomorrow.length === 0) {
        return (
          <div className="empty small clear">
            {HelperEmpty.renderImage('calendar')}
            <h4>{lang.t('home:label.nothingcomingup')}</h4>
          </div>
        )
      } else {
        var list = this.state.tomorrow
        list.sort((a,b) => Moment(a.data.date, 'DD/MM/YYYY HH:mm') - Moment(b.data.date, 'DD/MM/YYYY HH:mm'));
        return (
          <div className="clear">
            {list.map(item => (
              <div className="list bare" key={item.id}>
                <Link to={!item.data.group ? '/calendar/session/'+item.id : (item.data.availability === undefined ? '/calendar/group-session/'+item.id : '/calendar/availability/'+item.id)} onClick={() => this.selectSession(item.id)}>
                  {this.renderSession(item)}
                  <div className="main">
                    <h4>{Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('LT')}</h4>
                    <p>{this.getSessionLine1(item)}<br/>{this.getSessionLine2(item)}</p>
                  </div>
                  <div className="clear"></div>
                </Link>
              </div>
            ))}
            <div className="sv-10"></div>
            <Link to={ROUTES.CALENDAR} className="btn tertiary width-12 small">{lang.t('common:button.viewall')}</Link>
          </div>
        );
      }
    }
  }


  renderBirthdays() {
    if(this.state.birthdays.length === 0) {
      return (
        <div className="empty small clear mb-50">
          {HelperEmpty.renderImage('party')}
          <h4>{lang.t('home:label.uptodate')}</h4>
        </div>
      )
    } else {
      var list = this.state.birthdays
      list.sort((a, b) => a.title.localeCompare(b.title))
      return (
        <div className="clear mb-50">
          {list.map(item => (
            <div className="list bare" key={item.id}>
              <Link to={item.link} onClick={() => this.selectClient(item.id)}>
                {this.renderAvatarBday(item)}
                <div className="main">
                  <h4>{item.title}</h4>
                  <p>{item.desc}</p>
                </div>
                <div className="clear"></div>
              </Link>
            </div>
          ))}
        </div>
      );
    }
  }


  renderSidebar() {
    return (
      <div className="col-3">
        {this.renderActions()}

        <div className="mb-50">
          <h3>{lang.t('header:title.upcoming')}</h3>
          <div className="clear sv-10"></div>
          <div className="radios clear">
            <button className={this.state.showSessions === 'today' ? 'radio col-6 active' : 'radio col-6'} onClick={() => this.setState({showSessions: 'today'})}>{lang.t('home:label.today')}</button>
            <button className={this.state.showSessions === 'tomorrow' ? 'radio col-6 active' : 'radio col-6'} onClick={() => this.setState({showSessions: 'tomorrow'})}>{lang.t('home:label.tomorrow')}</button>
          </div>
          <div className="sv-20"></div>
          {this.renderToday()}
          {this.renderTomorrow()}
        </div>

        <h3>{lang.t('header:title.birthdays')}</h3>
        <div className="clear mb-10"></div>
        {this.renderBirthdays()}
      </div>
    )
  }


  renderImage(item) {
    if(item.data.image !== '') {
      var img = ''
      for(var image of this.state.images) {
        if(image.id === item.id) {
          img = image.image
        }
      }
      return (
        <div className="image clear"style={{backgroundImage: 'url('+img+')'}}></div>
      )
    }
  }


  renderAvatarFeed(id) {
    if(id === global.uidUser) {
      if(global.userImage === '' && global.userName !== null) {
        var inits = ''
        let arr = global.userName.split(' ')
        if(arr.length > 1) {
          inits = arr[0].charAt(0)+arr[1].charAt(0)
        } else {
          inits = arr[0].charAt(0)
        }
        return (
          <div className="avatar">
            <p>{inits}</p>
          </div>
        )
      } else {
        return (
          <div className="avatar" style={{backgroundImage: 'url('+global.userImage+')'}}> </div>
        )
      }
    } else {
      for(var client of this.state.clients) {
        if(client.id === id) {
          return (
            HelperClients.getImage(client)
          )
        }
      }
    }
  }


  getTooltipClient(item) {
    var label = lang.t('home:act.client')
    for(var client of global.clients) {
      if(client.id === item) {
        label = client.data.name
      }
    }
    for(var staff of global.userStaff) {
      if(staff.id === item) {
        label = staff.data.name
      }
    }
    if(item === global.uidUser) {
      label = lang.t('common:label.you')
    }
    return label
  }


  renderReactionTooltip(item, type) {
    var list = item.data.reaction1
    if(type === 'party') { list = item.data.reaction2 }
    if(type === 'smile') { list = item.data.reaction3 }
    if(type === 'sad') { list = item.data.reaction4 }
    var ar = []
    if(list !== '') {
      ar = list.split(',')
      ar.splice(0,1)
    }
    return (
      <div className="hover-info">
        {ar.map(item => (
          <span key={item.id}>{this.getTooltipClient(item)}<br/></span>
        ))}
      </div>
    )
  }


  renderReaction(item, list, type, parent) {
    var ar = list.split(',')
    ar.splice(0,1)
    var num = ar.length
    if(num === 0) {
      return (
        <div className={'reaction '+type} onClick={() => this.addReaction(item, type, parent)}>0</div>
      )
    } else {
      return (
        <div className={'reaction active '+type} onClick={() => this.addReaction(item, type, parent)}>{num}
          {this.renderReactionTooltip(item, type)}
        </div>
      )
    }
  }


  renderEdit(item) {
    if(item.data.author === global.uidUser) {
      return (
        <button className="btn tertiary small rgt edit" style={{marginRight: 20}} onClick={() => this.showModalPost(item.data.text, item.id, item.data.image, item.data.date)}>{lang.t('common:button.edit')}</button>
      )
    }
  }


  renderEditComment(item, id) {
    if(item.data.author === global.uidUser) {
      return (
        <button className="btn tertiary small rgt edit" style={{marginRight: 20}} onClick={() => this.showModalReply(item.data.text, item.id, id)}>{lang.t('common:button.edit')}</button>
      )
    }
  }


  renderComments(post) {
    if(post.comments !== undefined) {
      var list = post.comments
      list.sort((a,b) => Moment(a.data.date, 'X') - Moment(b.data.date, 'X'));
      return (
        <div className="comments">
          {list.map(item => (
            <div className="comment" key={item.id}>
              {this.renderAvatarFeed(item.data.author)}
              <h4 className="lft">{this.getName(item.data.author)} - {this.getDate(item.data.date)}</h4>
              <button className="btn tertiary small rgt close" onClick={() => this.showModalDelete(item.id, post.id, '')}>{lang.t('common:button.delete')}</button>
              {this.renderEditComment(item, post.id)}
              <p className="post clear mb-10">{item.data.text}</p>
              <div className="reactions">
                {this.renderReaction(item, item.data.reaction1, 'like', post.id)}
                {this.renderReaction(item, item.data.reaction2, 'party', post.id)}
                {this.renderReaction(item, item.data.reaction3, 'smile', post.id)}
                {this.renderReaction(item, item.data.reaction4, 'sad', post.id)}
                <div className="clear"></div>
              </div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderScheduled(item) {
    if(Moment(item.data.date, 'X') > Moment()) {
      return <span className="pill primary">Scheduled</span>
    }
  }


  renderContent() {
    if(this.state.community.length > 0) {
      var list = this.state.community
      list.sort((a,b) => Moment(b.data.date, 'X') - Moment(a.data.date, 'X'));
      
      return (
        <div>
           <div className="listheader clear">
            <h3>{lang.t('header:title.yourfeed')}</h3>
            <div className="clear"></div>
          </div>
          {list.map(item => (
            <div className="box feed mb-20" key={item.id}>
              {this.renderAvatarFeed(item.data.author)}
              <h4 className="lft">{this.renderScheduled(item)} {this.getName(item.data.author)} - {this.getDate(item.data.date)}</h4>
              <button className="btn tertiary small rgt close" onClick={() => this.showModalDelete(item.id, '', item.data.image)}>{lang.t('common:button.delete')}</button>
              {this.renderEdit(item)}
              {this.renderImage(item)}
              <p className="post clear mb-20">{item.data.text}</p>
              <div className="reactions">
                {this.renderReaction(item, item.data.reaction1, 'like', '')}
                {this.renderReaction(item, item.data.reaction2, 'party', '')}
                {this.renderReaction(item, item.data.reaction3, 'smile', '')}
                {this.renderReaction(item, item.data.reaction4, 'sad', '')}
                <div className="clear"></div>
              </div>
              {this.renderComments(item)}
              <button className="btn tertiary width-12 add small" onClick={() => this.showModalReply('', '', item.id)}>{lang.t('home:button.reply')}</button>
            </div>
          ))}
        </div>
      )
    } else {
      return (
        <div className="empty large pt-60 clear">
          <div className="illustration mb-30">
            <div className="img1"></div>
            <div className="img2"></div>
            <div className="img3"></div>
            <div className="img4"></div>
            {HelperEmptyImg.renderImage('community')}
          </div>
          <div className="data">
            <h3 className="mb-20">{lang.t('empty:communitynoposts.title')}</h3>
            <p className="mb-20">{lang.t('empty:communitynoposts.text')}</p>
            <button className="btn tertiary add width-12" onClick={() => this.showModalPost('', '', '', Moment().format('X'))}>{lang.t('home:button.createapost')}</button>
          </div>
        </div>
      )
    }
  }


  renderBase() {
    if(!global.userCommunity) {
      if(HelperBusiness.getRoleIncl('')) {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content withtabs">
              <ListEmpty id='communityoff'/>
            </div>
            <Header type='home' active='community'/>
            <Navigation active='overview'/>
          </div>
        )
      } else {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content withtabs">
              <ListEmpty id='community'/>
            </div>
            <Header type='home' active='community'/>
            <Navigation active='overview'/>
          </div>
        )
      }
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content withtabs">
            <div className="col-9 withsidebar">
              {this.renderContent()}
            </div>
            {this.renderSidebar()}
          </div>
          <Header type='home' active='community'/>
          <Navigation active='overview'/>
          {this.renderModalPost()}
          <ModalForm type={'createreply'} show={this.state.showModalReply} onHide={() => this.hideModals()} value1={this.state.value} updateValue1={(event) => this.setState({value: event})} clickMainButton={() => this.createReply()}/>
          <ModalForm type={'editreply'} show={this.state.showModalReplyEdit} onHide={() => this.hideModals()} value1={this.state.value} updateValue1={(event) => this.setState({value: event})} clickMainButton={() => this.updateReply()}/>
          <ModalMessageSimple type='deletepost' show={this.state.showModalDeletePost} onHide={() => this.hideModals()} clickMainButton={() => this.deletePost()}/>
          <ModalMessageSimple type='deletereply' show={this.state.showModalDeleteReply} onHide={() => this.hideModals()} clickMainButton={() => this.deleteReply()}/>
        </div>
      )
    }
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['home','common','header','empty','actions','messaging','modals'])(withRouter(withAuthorization(condition)(CommunityPage)));