import React, { Component } from 'react';
import { ResponsiveBar } from '@nivo/bar';



class WidgetChartBar extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      data: props.data ?? [
        {
          "id": "japan",
          "color": "#1DC5C9",
          "data": []
        },
      ],
      key: props.key1 ?? 'before',
      colors: props.colors ?? global.themeColors,
      min: props.min ?? 0,
      max: props.max ?? 100
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      data: props.data ?? [
        {
          "id": "japan",
          "color": "#1DC5C9",
          "data": []
        },
      ],
      key: props.key1 ?? 'before',
      colors: props.colors ?? global.themeColors,
      min: props.min ?? 0,
      max: props.max ?? 100
    }
  }


  renderLight() {
    return (
      <ResponsiveBar
        data={this.state.data}
        keys={[
          this.state.key,
      ]}
        indexBy="date"
        margin={{ top: 5, right: 0, bottom: 5, left: 50 }}
        padding={0.3}
        groupMode="grouped"
        innerPadding={3}
        minValue={this.state.min}
        maxValue={this.state.max}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={[this.state.colors[0]]}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        enableLabel={false}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        theme={{
          tooltip: {
            container: {
              background: "#ffffff",
              fontSize: 12
            },
          },
        }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={e=>e.id+": "+e.formattedValue+" in country: "+e.indexValue}
      />
    )
  }


  renderDark() {
    return (
      <ResponsiveBar
        data={this.state.data}
        keys={[
          this.state.key,
      ]}
        indexBy="date"
        margin={{ top: 5, right: 0, bottom: 5, left: 50 }}
        padding={0.3}
        groupMode="grouped"
        innerPadding={3}
        minValue={this.state.min}
        maxValue={this.state.max}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={[this.state.colors[0]]}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        enableLabel={false}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        theme={{
          tooltip: {
            container: {
              background: "#000011",
              fontSize: 12
            },
          },
          "grid": {
            "line": {
                "stroke": "#323439",
                "strokeWidth": "1",
            }
            
          },
        }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
        enableGridX={false}
        enableGridY={false}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={e=>e.id+": "+e.formattedValue+" in country: "+e.indexValue}
      />
    )
  }


  render() {
    return (
      <div className="chart">
        <div className="show-light chart">
          {this.renderLight()}
        </div>
        <div className="show-dark chart solid">
          {this.renderDark()}
        </div>
      </div>
    )
  }
}


export default WidgetChartBar;